import React, { useRef, useState } from 'react'
import CustomAudioPlayer from '../audioPlayer/CustomAudioPlayer'
import './ListenStory.scss'

const ListenStory = ({ url, imageUrl, name, description, authorName,preloadedAudio }) => {


    return (
        <div className='listen_story_container'>
            <div className='listen_story_image_container'>
                <img src={imageUrl} alt='cover image' />
            </div>
            <div className='listen_story_details_container'>
                <p className='name'>{name}</p>
                <p className='description'>{description}</p>
                <p className='author_name'><span>By </span>{authorName}</p>

                <CustomAudioPlayer url={url} preloadedAudio={preloadedAudio} />
            </div>
        </div>
    )
}

export default React.memo(ListenStory)