import React, { useEffect, useState, useRef } from 'react';
import { FaPlay, FaPause, FaVolumeMute, FaVolumeUp, FaVolumeDown } from "react-icons/fa";
import WaveSurfer from '@wavesurfer/react';
import Forward10Seconds from 'assets/image/forward-10-seconds.svg';
import Backward10Seconds from 'assets/image/backward-10-seconds.svg';
import Loader from "assets/image/loader2.gif";
import './CustomAudioPlayer.scss';
import { Icon, Spin } from 'antd';

const CustomAudioPlayer = ({ url, preloadedAudio }) => {

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(0.5);
    const [isLoading, setIsLoading] = useState(!preloadedAudio)
    const wavesurferRef = useRef(null);

    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

    const onReady = (wavesurfer) => {
        wavesurferRef.current = wavesurfer;

        if (wavesurferRef.current) {
            wavesurferRef.current.on('play', () => setIsPlaying(true));
            wavesurferRef.current.on('pause', () => setIsPlaying(false));
            wavesurferRef.current.on('audioprocess', () => setCurrentTime(wavesurferRef.current.getCurrentTime()));
            wavesurferRef.current.on('ready', () => { setDuration(wavesurferRef.current.getDuration()); setIsLoading(false) });
            wavesurferRef.current.on('loading', (progress) => {
                if (progress < 100) {
                    setIsLoading(true);
                }
            });
        }
    }

    // Play or Pause the audio
    const togglePlayPause = () => {
        if (wavesurferRef.current && !isLoading) {
            wavesurferRef.current.isPlaying() ? wavesurferRef.current.pause() : wavesurferRef.current.play();
        }
    };

    // Skip forward/backward
    const skipForward = () => {
        if (wavesurferRef.current && !isLoading) {
            wavesurferRef.current.skip(10);
        }
    };

    const skipBackward = () => {
        if (wavesurferRef.current && !isLoading) {
            wavesurferRef.current.skip(-10);
        }
    };

    // Volume controls
    const handleVolumeChange = (volumeValue) => {
        const newVolume = parseFloat(volumeValue);
        setVolume(newVolume);
        if (wavesurferRef.current) {
            wavesurferRef.current.setVolume(newVolume);
        }
    };

    const handleMuteUnmute = () => {
        if (wavesurferRef.current) {
            if (volume > 0) {
                wavesurferRef.current.setVolume(0);
                setVolume(0);
            } else {
                wavesurferRef.current.setVolume(1);
                setVolume(1);
            }
        }
    };

    // Format time for display
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    useEffect(() => {
        return () => {
            if (wavesurferRef.current) {
                wavesurferRef.current.destroy();
            }
        };
    }, []);

    return (
        <div className='audio_player_container'>
            <div className='waveform'>
                <WaveSurfer
                    height={50}
                    waveColor="#e6cdfb"
                    progressColor="#9134de"
                    url={url}
                    onReady={onReady}
                    audioBuffer={preloadedAudio}
                    barWidth={2}
                    // barHeight={4}
                    width="100%"
                    className="waveform"
                />
            </div>
            <div className='audio_player_controls_container'>
                {/* Backward 10 Seconds Button */}
                <button className='backward' onClick={skipBackward}>
                    <img src={Backward10Seconds} alt="-10s" />
                </button>
                {/* Play/Pause Button */}
                <button className='play_pause' onClick={togglePlayPause}>
                    {isLoading?
                    <Spin indicator={antIcon}/>
                    : isPlaying ? <FaPause /> : <FaPlay />}
                </button>
                {/* Forward 10 Seconds Button */}
                <button className='forward' onClick={skipForward}>
                    <img src={Forward10Seconds} alt="10s" />
                </button>
            </div>
            {/* Volume Control */}
            <div className='audio_story_bottom_container'>
                <div className="time-info">
                    {formatTime(currentTime)} / {formatTime(duration)}
                </div>

                <div className='audio_player_volume_bar_container'>
                    {volume === 0 ? (
                        <FaVolumeMute onClick={handleMuteUnmute} />
                    ) : volume < 0.5 ? (
                        <FaVolumeDown onClick={handleMuteUnmute} />
                    ) : (
                        <FaVolumeUp onClick={handleMuteUnmute} />
                    )}
                    <input
                        aria-label="volume"
                        name="volume"
                        type="range"
                        min={0}
                        step={0.1}
                        max={1}
                        value={volume}
                        className="audio_player_volume_bar"
                        onChange={(e) => handleVolumeChange(e.currentTarget.valueAsNumber)}
                        style={{
                            background: `linear-gradient(to right, #9124de ${volume * 100}%, #ddd ${volume * 100}%)`,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CustomAudioPlayer;
