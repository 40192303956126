import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';
import "./index.scss";
import wowImage from 'assets/image/wow.png';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as studentActions from '../../store/student/actions';
import DefaultUserIcon from '../../assets/image/default-user.png';
import {CrossIcon} from '../../assets/image/svg';

const ChildrenModal = (props) => {
    const [childrenData, setChildrenData] = useState([])
    useEffect(() => {
        if (props.visible) {
            (async () => {
                try {
                    let res = await props.getBatchChildrenRequest(props.batchId)
                    console.log('ssss', res)
                    res = res.filter(item => item.id !== props?.student?.id)
                    setChildrenData(res)
                } catch (e) {

                }
            })()
        }
    }, [props.visible])

    console.log(childrenData)

    const childrenCards = childrenData?.length > 0 ?
        childrenData?.map((child, index) => (
            <div className="children-card" key={index}>
                <div className={'batchmate-content'}>
                    <div className={'profile'}>
                        <img className="listing-row-profile-pic" style={{backgroundColor: 'white'}}
                             src={child.image ? child.image : DefaultUserIcon}/>
                        <div className="wow-pic">
                            <img alt=""
                                 src={wowImage}
                                 style={child.freadom_child?.is_wow_student ? null : {visibility: "hidden"}}/>
                        </div>
                    </div>
                    <div className={'batchmate-detail'}>
                        <div className="name">
                            <h2>{child.name}</h2>
                        </div>
                        <div className="stats">
                            <div className="freadom-points">
                                <p className="purple-text">{child.freadom_child?.freadom_point || '-'}</p>
                                <p>Freado Points</p>
                            </div>
                            <div className="streak-counts">
                                <p className="purple-text">{child.freadom_child?.streak || '-'}</p>
                                <p>Streak Count</p>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        )) :
        <div className={'no-data-label'}>No batchmates present</div>

    return (
        <Modal
            wrapClassName="children-modal"
            maskStyle={{
                background: "rgba(0,0,0,0.45)"
            }}
            title="My Batchmates"
            width={950}
            visible={props.visible}
            onOk={null}
            onCancel={props.closeModal}
            okText="Submit"
            closeIcon={
                <i aria-label="icon: close" class="anticon anticon-close ant-modal-close-icon"><CrossIcon/></i>
            }
        >
            <div className="children-cards d-flex wrap">
                {childrenCards}
            </div>
        </Modal>
    );
}
export default connect(
    ({student}) => ({student}),
    dispatch => bindActionCreators({...studentActions}, dispatch)
)(ChildrenModal);
