import React, { Component } from "react";
import { Button, Form, Input, Select, TreeSelect, DatePicker, Checkbox } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OtpInput from "react-otp-input";

import * as userActions from "store/user/actions";
import LoginImg from "assets/imagev2/freadom_login.svg";
import { storage as LocalStorage } from "services/config/storage";
import store from "../../store";
import CloseBlackIcon from "assets/image/close-black.svg";
import * as EVENT from "../../utils/eventKeys";
import loader from "../../assets/image/loader.gif";
import moment from 'moment';
import ContentSrch from "../Navbar/ContentSrch";
import * as teacherActions from "store/teacher/actions";
import successImg from "../../assets/image/check_img.png";
import * as homeActions from "store/home/actions";
import * as storyActions from "store/story/actions";
import * as activityActions from "store/activities/actions";
import './addAssignment.scss';

const { Option } = Select;
const otpBoxStyle = {
    // width: '100%',
    width: "45px",
    textAlign: "center",
    height: "45px",
    outline: "none",
    fontSize: "24px",
    border: "1px solid rgb(238, 238, 238)",
    borderRadius: "10px",
};
class AddAssignment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enteredDescription: "",
            enteredName: "",
            enteredDeadline: "",
            enteredAssignee: "",
            NameDescriptionEntered: false,
            selectedGrade: null,
            assigneeType: null,
            shwContentSearch: true,
            contentDeadLineSet: false,
            storyData: null,
            content: null,
            contentType: null,
            error: null,
            schoolClassData: [],
            childDataFetched: false,
            teacherSchoolClass: null,
            selectedSchoolClassChildren: null,
            allDataFetched: false,
            allClassMatesData: null,
            disabledContinueButton: false,
            success: false,
            DefaultContentSelected: [],
            selectedContentIds: [],
            showSelect: false
        };
    }

    componentDidMount = () => {
        // console.log(this.props);
        // get all sections of child
        const currentChild = LocalStorage.fetch.defaultChild();
        // console.log("currentChild:", currentChild);
        const gradeName = currentChild?.grade?.name || '';
        // console.log("gradeName:", gradeName);
        this.processTreeData(currentChild?.school_class, gradeName);

        var storyData = [
            {
                "label": "Acrobatic Yoga' team of differently abled children",
                "type": "News",
                "key": "226d02cd-b000-4f4a-8894-5829eeca6626"
            },
            {
                "label": "Trim, the Small Fish",
                "type": "Story",
                "key": "be83f20a-923d-46f1-884d-1d771c904315"
            },
            {
                "label": "Artistic Analogies",
                "type": "Activity",
                "key": "24f27ea0-252d-4928-8042-d9ab64cf58e2"
            }
        ];

        this.setState({ storyData: storyData });

        const defaultChild = LocalStorage.fetch.defaultChild();
        // console.log("teacherlClass", this.state.childDataFetched, defaultChild, defaultChild?.TeacherSchoolClass);
        this.setState({ teacherSchoolClass: defaultChild.TeacherSchoolClass });
        this.fetachAllClassMates();

    }
    processTreeData = (data, gradeName) => {
        // console.log("data", data);
        var result = [];
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                // console.log(key, data[key]);
                let child = { "value": data[key].id, "label": gradeName + " - " + data[key].name, "children": [] };
                result.push(child);
            }
        }
        this.setState({
            allSections: result
        });
        // console.log("result", result);
    }

    submitNameDescription = (e) => {
        e.preventDefault();
        // console.log(this.state);
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log("Received values of form: ", values);
            }
        });
        this.setState({ NameDescriptionEntered: true })
    }

    treeselectOnChange = (value) => {
        // console.log(value);
        this.setState({ enteredAssignee: value })
    }

    onGradeChange = (value) => {
        this.setState({ selectedGrade: value })
    }
    submitAssignmentData = (e) => {
        e.preventDefault();
        this.setState({ disabledContinueButton: true })
        // console.log(this.state);
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // console.log("Received values of form: ", values);
            }
        });
        let content_type = this.state.contentType;
        if( content_type === "news_fread") {
            content_type = "news";
        }
        var payload = {
            name: this.state.enteredName,
            description: this.state.enteredDescription,
            deadline: this.state.enteredDeadline,
            assignee: this.state.enteredAssignee,
            assignee_type: this.state.assigneeType,
            content_id: this.state.selectedContentIds.value,
            content_type: content_type.toLowerCase(),
        }

        this.props.createAssignment(payload).then((res) => {
            // console.log("res", res);
            if (res && (res.status != 200 || res.status != 201) && !res.success) {
                this.setState({ error: "Something went wrong. Please try again." });
                // this.props.close();
            } else {
                this.props.updateAssignments();
                this.setState({ success: true });
                // close after 3 seconds
                setTimeout(() => {
                    this.props.close();
                }, 3000);
            }

        })

    }
    assigneeSelectionDone = (value) => {
        this.setState({ assigneeType: value });
    }
    assigneeTypeChange = (value) => {
        this.setState({ assigneeType: value });
    }
    contentDeadLineDone = (value) => {
        console.log(value?.target);
        this.setState({ contentDeadLineSet: true })
    }
    contentSelectChange = (key, value) => {
        // console.log(key, value);
        // get the selected option key,value
        // console.log(value);
        this.setState({ content: key, contentType: value.props?.children[2] });
    }

    fetachAllClassMates = async () => {
        try {

            const result = await this.props.getAllClassMates();
            this.setState({ schoolClassData: result, childDataFetched: true });
            await this.findSelectedSchoolClassChildren();
        } catch (err) {
            console.error('Error fetching children data: ', err);
        }
    }

    findSelectedSchoolClassChildren = async () => {

        const schoolClassData = this.state.schoolClassData;
        const teacherlClass = this.state.teacherSchoolClass;

        const selectedSchoolClassChildren = this.getSelectedSchoolClassChildren(schoolClassData);
        this.setState({ selectedSchoolClassChildren: selectedSchoolClassChildren });

        if (this.state.selectedSchoolClassChildren.length > 0) {
            await this.fetAllChildDetails();
        }
    }

    getSelectedSchoolClassChildren = (schoolClassData) => {

        let allClassmates = [];
        schoolClassData.forEach(item => {
            allClassmates = allClassmates.concat(item.classmates);
        });


        // console.log("all_classmates_ids:", allClassmates);

        return allClassmates;

    }

    fetAllChildDetails = async () => {
        let childResponses = new Object();
        const { selectedSchoolClassChildren } = this.state;
        var all_children_data = [];
        selectedSchoolClassChildren && selectedSchoolClassChildren.length > 0 && selectedSchoolClassChildren.forEach(async (child) => {
            try {
                // const resp = await this.props.getChildDetails(childId);
                let resp = {
                    "id": child.child_id,
                    "name": child.child__user__name,
                    "school_class": [
                        {
                            "name": child.school_class__name,
                            "id": child.school_class_id
                        }
                    ]
                }
                childResponses[child.child_id] = resp;
                // console.log("response:", resp);
                const new_childArr = this.treeformData(resp, all_children_data);
                this.setState({ allDataFetched: true })
            } catch (error) {
                console.error(`Error fetching child details for child id ${child.child_id}:`, error);
            }
        });
        this.setState({ allClassMatesData: childResponses, allChildren: all_children_data });
        // console.log("allClassMatesData:", this.state.allClassMatesData);
        // console.log("all_children_data:", all_children_data);
    }

    selectOption = (value) => {
        this.setState({ showSelect: true });
        console.log(value);
        var selectedOption = { "label": value[0]._name, "value": value[0]._source.id, "type": value[0]._index };
        // add to defaultContentSelected
        // var alreadySelected = this.state.DefaultContentSelected;
        // // check if already selected
        // console.log("selected ids before:", this.state.selectedContentIds);
        // if (alreadySelected.indexOf(selectedOption.label) === -1){
        //     alreadySelected.push(selectedOption.label);
        //     this.setState({ selectedContentIds: selectedOption, contentType: selectedOption.type });
        // } else {
        //     console.log("else ");
        //     // remove from selectedConentIds
        //     alreadySelected.splice(alreadySelected.indexOf(selectedOption.label), 1);
        //     // const newlySelected = this.state.selectedContentIds.filter(item => item.label !== selectedOption.label)
        //     this.setState({ selectedContentIds: selectedOption, contentType: selectedOption.type });
        //     console.log("selectedContentIds:", this.state.selectedContentIds);
        // }
        this.setState({ DefaultContentSelected: [selectedOption.label], selectedContentIds: selectedOption, contentType: selectedOption.type });
        // console.log("alreadySelected:", alreadySelected);
        // this.setState({ DefaultContentSelected: alreadySelected });
        // console.log("selected ids after:", this.state.selectedContentIds);

    }
    handleContentChange = (value) => {
        console.log(value);
        const choiceElement = document.querySelector('.ant-select-selection__choice');
        choiceElement.style.display = 'none';
        // this.setState({ content: value });
        if (value.length === 0) {
            // Handle deselection
            this.setState({ content: null }); // Reset the content state when nothing is selected

        } else {
            // Handle selection
            this.setState({ content: value }); // Update the content state with the selected value(s)
        }
    }
    treeformData = (data, all_children_data) => {
        // console.log("data:", data);
        // console.log("all_children_data:", all_children_data);
        if (data) {
            var school_class_name = data.school_class[0].name;
            var school_class_id = data.school_class[0].id;
            var found = false;
            // traverse the all_children_data object using for loop
            for (var key in all_children_data) {
                if (all_children_data[key].value == school_class_id) {
                    found = true;
                    all_children_data[key].children.push({ "label": data.name, "value": data.id, "children": [] });
                    break
                }
            }
            if (!found) {
                all_children_data.push({ "label": school_class_name, "value": school_class_id, "children": [{ "label": data.name, "value": data.id, "children": [] }] });
            }

        }

        // console.log("all_children_data:", all_children_data);
        return all_children_data;
    }
    async fetchChildrenData() {
        try {
            let res = await this.props.getBatchChildrenRequest(this.props.batchId);
            res = res.filter(item => item.id !== this.props?.student?.id);
            this.setState({ childrenData: res });
        } catch (e) {
            console.error('Error fetching children data: ', e);
        }
    }

    dateChanged = (date, dateString) => {
        this.setState({ enteredDeadline: dateString });
    }

    disabledDate = current => {
        return current && (!current.isSame(moment(), 'year') || current.isBefore(moment(), 'day'));
    };

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        const { enteredName, enteredDescription, enteredDeadline, enteredAssignee, NameDescriptionEntered,
            assigneeType, shwContentSearch, contentDeadLineSet, storyData, error, disabledContinueButton,
            success, DefaultContentSelected, content } = this.state;
        return (
            <div className="component-profile">
                <div className="description-card" style={{overflowX:'hidden'}}>

                    <div className="flex">
                        <p className="total-activities-text">CREATE ASSIGNMENT</p>
                        <img
                        src={CloseBlackIcon}
                        className="close-icon"
                        alt="close"
                        onClick={this.props.close}
                        />
                    </div>
                    
                    <div className="description-container">

                        {/* create a form with name and description  */}
                        {!NameDescriptionEntered ?
                            <form onSubmit={this.submitNameDescription}>
                                <Form.Item
                                    validateStatus={this.state.error ? "error" : "success"}
                                    help={this.state.error}
                                >
                                    <Input
                                        placeholder="Name of the assignment"
                                        value={enteredName}
                                        onChange={(e) => {
                                            this.setState({ enteredName: e.target.value });
                                        }}
                                        style={{ width: '100%', height: '50px' }} // Adjust width and height as needed
                                    />
                                </Form.Item>

                                <Form.Item
                                    validateStatus={this.state.error ? "error" : "success"}
                                    help={this.state.error}
                                >
                                    <Input.TextArea
                                        placeholder="Description of the assignment"
                                        value={enteredDescription}
                                        onChange={(e) => {
                                            this.setState({ enteredDescription: e.target.value });
                                        }}
                                        autoSize={{ minRows: 2, maxRows: 6 }} // This will allow the textarea to expand vertically
                                        style={{ width: '100%', lineHeight: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '22px 12px !important' }} // Adjust width as needed
                                    />
                                </Form.Item>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="submit-button"
                                    disabled={this.state.enteredName.trim() === "" || this.state.enteredDescription.trim() === ""}
                                    style={{ justifyContent: 'center' }}
                                >
                                    Continue
                                </Button>

                            </form>
                            : null}
                        {NameDescriptionEntered && !contentDeadLineSet ?
                            <Form layout="vertical" onSubmit={this.contentDeadLineDone} >

                                <div className="search-content">
                                    Search For Content
                                    <div className="global-search">
                                        <Form.Item
                                            validateStatus={this.state.error ? "error" : "success"}
                                            help={this.state.error}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select content!',
                                                },
                                            ]}
                                        >
                                            {this.state.showSelect &&
                                                <Select
                                                    mode="tags"

                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    value={this.state.DefaultContentSelected}
                                                    onChange={this.handleContentChange}
                                                    dropdownRender={menu => (
                                                        <> </>
                                                    )}
                                                />
                                            }

                                            <ContentSrch
                                                selectOption={this.selectOption}
                                                shwContentSearch={shwContentSearch}
                                                isTeacherDashboard={true}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div style={{ marginTop: window.innerWidth>767?"10%":"2%" }}>
                                    Please select deadline
                                    <Form.Item
                                        validateStatus={this.state.error ? "error" : "success"}
                                        help={this.state.error}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select date!',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            placeholder="Deadline"
                                            value={enteredDeadline ? moment(enteredDeadline) : null}
                                            onChange={this.dateChanged}
                                            disabledDate={this.disabledDate}
                                        />
                                    </Form.Item>
                                </div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="submit-button"
                                    disabled={this.state.enteredDeadline === "" || this.state.content === ""}
                                >
                                    Continue
                                </Button>
                            </Form>
                            : null}
                        {NameDescriptionEntered && contentDeadLineSet && !success ?
                            // create a form with deadline and assignee
                            <form onSubmit={this.submitAssignmentData}>
                                <div>
                                    Please select the assignee type
                                    <div>
                                        <Form.Item
                                            validateStatus={this.state.error ? "error" : "success"}
                                            help={this.state.error}
                                        >
                                            <Select
                                                showSearch
                                                optionFilterProp="Students"
                                                dropdownClassName="drop-down"
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={this.assigneeTypeChange}
                                                onFocus={this.onFocus}
                                                onBlur={this.onBlur}
                                                onSearch={this.onSearch}
                                                placeholder={<span style={{ color: 'grey' }}>Select Assignee Type</span>}
                                            >
                                                <Option key="children" value="child">
                                                    Students
                                                </Option>
                                                <Option key="sections" value="section">
                                                    Sections
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                {assigneeType ?
                                    <div className="assignee" style={{ width: "100%" }}>
                                        <div className="assignee-item">
                                            <Form.Item
                                                validateStatus={this.state.error ? "error" : "success"}
                                                help={this.state.error}
                                            >
                                                <TreeSelect
                                                    showSearch
                                                    mode="multiple"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    dropdownStyle={{ maxHeight: 230, width: 170, overflow: 'auto', zIndex: "100000", left: 660 }}
                                                    placeholder={<span style={{ color: 'grey' }}> {assigneeType === "child" ? "Please select students" : "Please select sections"} </span>}
                                                    allowClear
                                                    treeDefaultExpandAll={true}
                                                    treeCheckable={true}
                                                    onChange={this.treeselectOnChange}
                                                    treeData={assigneeType === "child" ? this.state.allChildren : this.state.allSections}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    : null}
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="submit-button"
                                    disabled={assigneeType === null || enteredAssignee === "" || enteredAssignee === [] || enteredAssignee.length === 0}
                                >
                                    Create
                                </Button>
                            </form>
                            : null}
                        {success ?
                            <div className="success" style={{ marginLeft: "20%" }}>
                                <img
                                    src={successImg}
                                    style={{ width: "100px", height: "100px", marginLeft: "20%" }}
                                />
                                <br />
                                <p> Successfully created the assignment. </p>
                            </div> : null}

                        {/* {error ? <div className="error">{error}</div> : null} */}
                    </div>
                </div>
            </div>
        );
    }
}

const connectedComponent = connect(
    ({ user, teacher, story, activity, appConfig, home }) => ({
        ...user,
        ...appConfig,
        ...teacher,
        ...story,
        ...activity,
        ...home,
    }),
    (dispatch) => bindActionCreators({
        ...userActions,
        ...teacherActions,
        ...homeActions,
        ...storyActions,
        ...activityActions,
    }, dispatch)
)(AddAssignment);


export default Form.create()(connectedComponent);