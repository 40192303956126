// ReadAlongLayout.js

import React, { useState } from "react";
import { withRouter } from "react-router-dom"; // Import withRouter
import "./ReadAlong.scss";
import BannerImage from "../../assets/image/bannerreadalong.png";
import { STORY_DATA } from './constants';
import EpubStoryCard from "../../components/EpubStoryCard";
import EPubStoryModal from "../../components/EPubStoryModal";
import { Modal } from "antd";

const ReadAlongLayout = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [storyData, setStoryData] = useState({});

  const openModal = (storyKey) => {
    console.log("clicked on openModal");
    var story_data = STORY_DATA[storyKey];
    setStoryData(story_data);
    
    // Redirect the user to '/storyDetails'
    props.history.push(`/read-along/readStory/${storyKey}`);

    // If you still want to open the modal, uncomment the following line
    // setIsModalOpen(true);
  };

  const closeModal = () => {
    console.log("clicked on closedModal");
    setIsModalOpen(false);
  };

  return (
    <div className="read-along-layout">
      <div className="banner">
        <img src={BannerImage} alt="Banner" className="banner-img" style={{marginTop:'3px'}}/>
      </div>
      <div className="content-container">
        <div className="grid">
          {Object.entries(STORY_DATA).map(([storyKey, storyDetails], index) => (
            <EpubStoryCard
              key={index}
              storyKey={storyKey}
              story={storyDetails}
              openModal={openModal}
              closeModal={closeModal}
            />
          ))}
        </div>
      </div>
      <Modal
        title="Epub Story"
        centered
        className="story-modal"
        visible={isModalOpen}
        onCancel={() => closeModal()}
        footer={null}
        destroyOnClose
      >
        <EPubStoryModal
          data={storyData}
          isOpen={true}
          onClose={() => closeModal()}
          close={() => closeModal()}
        />
      </Modal>
    </div>
  );
};

export default withRouter(ReadAlongLayout); // Wrap the component with withRouter
