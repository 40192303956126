import mixpanel from 'mixpanel-browser';
import { storage as LocalStorage } from "services/config/storage";

// mixpanel.init('e795e54edb06e4c8490fefffbdbfb2f4');
// mixpanel.init('ae3f830f0e9609d5ea66b1378a5ca298');
mixpanel.init("a351faf65cb6fc16d274992587feb9b9");
// 461fc574432981464730ba5f409082ff
// a351faf65cb6fc16d274992587feb9b9;
let env_check = process.env.NODE_ENV === 'production';
// let env_check = true;

let payload = {
  childData: LocalStorage.fetch.childData() ? LocalStorage.fetch.childData().list : '',
  parentData: LocalStorage.fetch.parentData(),
  selectedChild: LocalStorage.fetch.defaultChild()
}

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  // track: (name, props) => {
  //   if (env_check) mixpanel.track(name, {...payload, ...props});
  // },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
