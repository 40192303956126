import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import "./index.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as studentActions from "../../store/student/actions";
import {
  CrossIcon,
  MailIcon,
  PhoneIcon,
  QuestionMarkIcon,
} from "../../assets/image/svg";

const { TextArea } = Input;
const CustomerSupportModal = (props) => {
  const [childrenData, setChildrenData] = useState([]);
  const [feedbackText, setfeedbackText] = useState([]);

  useEffect(() => {}, [props.visible]);

  return (
    <Modal
      wrapClassName="support-modal"
      maskStyle={{
        background: "rgba(0,0,0,0.45)",
      }}
      title=""
      width={400}
      visible={props.visible}
      onOk={null}
      onCancel={props.closeModal}
      okText="Submit"
      closeIcon={
        <i
          aria-label="icon: close"
          class="anticon anticon-close ant-modal-close-icon"
        >
          <CrossIcon />
        </i>
      }
    >
      <div className={"container"}>
        <QuestionMarkIcon width={65} height={65} />
        <div className={"heading"}>
          <h4>CUSTOMER SUPPORT</h4>
          <p>Having trouble using Freadom?</p>
          <p>Reach out to us at:</p>
        </div>
        <div className="contact-details">
          <div className={"phone"}>
            <PhoneIcon />
            <p>+91 80689 36810</p>
          </div>
          <div className="email">
            <MailIcon />
            <p>support@stones2milestones.com</p>
          </div>
        </div>
        <div className="feedback-input">
          <TextArea
            onChange={(e) => {
              setfeedbackText(e.target.value);
            }}
            placeholder={"Enter feedback here..."}
            value={feedbackText}
            className="feedback"
            rows={7}
          />
        </div>
        <Button type="primary" onClick={props.closeModal}>
          SHARE FEEDBACK
        </Button>
      </div>
    </Modal>
  );
};
export default connect(
  ({ student }) => ({ student }),
  (dispatch) => bindActionCreators({ ...studentActions }, dispatch)
)(CustomerSupportModal);
