import React from 'react';
import emptyAnnouncement from '../../assets/image/emptyAnnouncement.png';
import './AnnouncementsEmpty.scss';

const AnnouncementsEmpty = ({message = 'Oh ohh! No announcements yet'}) => {
    return (
        <div className="empty-announcements">
            <img src={emptyAnnouncement}/>
            <p>{message}</p>
        </div>
    );
}

export default AnnouncementsEmpty;
