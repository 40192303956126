import React from "react";
import "./index.scss";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const TestList = (props) => {
    console.log("TestList list====",props);
    // return props?.data?.map(test => {
        return <section
            className="post-heading pb-3 pl-4 pr-4 mb-3 position-relative test-row ht-lmt">
            <div className="media m-0 p-0">
                <div className="media-body mediaBody ml-0 p-0">
                    {/* <div className="row">
                    <div className="col-6">
                        <button className="event-btn testbtn">{test.tag}</button>

                    </div>
                    <div className="col-6">
                        <h6 className="test-date testdatedata">{renderDateTime(test?.created_at)}</h6>

                    </div>
                    </div> */}
                    <h4 className="media-heading mb-0 ml-0 text-left test-heading">Assessment - 1</h4>
                </div>
            </div>
            <div className="post-body postBodyRight">
                {/* <h6 className="post-text pt-1">test description</h6> */}
            </div>
            <div>
                <button onClick={() => props.history.push("/assessment", {urlToRedirect:props.location.pathname})} className="attend-btn">
                    TAKE THE TEST
                </button>
            </div>
            <span className="hr-line"/>
        </section>
    // })
}
const renderDateTime = (date) => {
  let output = [];
  let timestamp = date * 1000;
  if (moment().diff(timestamp) < 0) {
    output.push(<div style={{ color: "#F24E29" }}>Today</div>);
    output.push(<div className={"dot red"} />);
    output.push(
      <div style={{ color: "#F24E29" }}>
        {moment(timestamp).format("HH:mm A")}
      </div>
    );
  } else {
    output.push(
      <div style={{ opacity: 0.5 }}>{moment(timestamp).format("DD/MM/YY")}</div>
    );
    output.push(<div className={"dot light"} />);
    output.push(
      <div style={{ opacity: 0.5 }}>{moment(timestamp).format("HH:mm A")}</div>
    );
  }
  return output;
};

export default connect(null, null)(withRouter(TestList));
