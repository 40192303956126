import React from 'react';
import "./index.scss";
import {Form, Input, Select} from 'antd';
import Calendar from 'assets/image/calendar.svg'
import CardSelector from "../CardSelector";

const {TextArea} = Input;
const {Option} = Select;

const FormField = ({getFieldDecorator, ...props}) => {
    switch (props.fieldType) {
        case 'label':
            return (
                <div className={'form-field'}>
                    {<p>{props.title}</p>}
                </div>
            )
        case "text":
        case "number":
            return (
                <div className="form-field">
                    <Form.Item
                        id={props.name}>
                        {<p>{props.title}</p>}
                        {
                            getFieldDecorator(props.name, {
                                rules: [
                                    {required: true, message: props.error},
                                    {
                                        pattern: props.regex, message: props.regexErrorMessage
                                    }
                                ],
                            })
                            (<Input
                                type={props.fieldType}
                                onChange={props.onValueChange} name={props.name} value={props.value}
                                className="form-field-grey" placeholder={props.placeholder} compact={true}/>
                            )
                        }
                    </Form.Item>
                </div>
            )
        case "textarea":
            return (
                <div className="form-field">
                    <Form.Item
                    >
                        <p>{props.title}</p>
                        {
                            getFieldDecorator(props.name, {
                                rules: [{required: true, message: props.error}, {
                                    pattern: props.regex, message: props.regexErrorMessage
                                }],
                            })(<TextArea onChange={props.onValueChange} name={props.name}
                                         placeholder={props.placeholder}
                                         value={props.value} className="textarea-grey" rows={5}/>)
                        }
                    </Form.Item>
                </div>
            )
        case "dropdown":
            return (
                <div className="form-field">
                    <Form.Item
                    >
                        <p>{props.title}</p>
                        {
                            getFieldDecorator(props.name, {
                                rules: [{required: true, message: props.error}],
                            })(<Select onChange={props.onValueChange} defaultValue="Select One"
                                       value={(props.value.trim() == '' ? "Select One" : props.value)}
                                       style={{width: "100%"}}
                                       className='select-grey'>
                                {
                                    props.options.map(element => {
                                        return (
                                            <Option value={element.value}>{element.title}</Option>
                                        )
                                    })
                                }
                            </Select>)}
                    </Form.Item>
                </div>
            )
        case "cardSelector":
            return <CardSelector getFieldDecorator={getFieldDecorator} {...props} bgImage={Calendar}/>
        default:
            return null;
    }
}

export default FormField;
