const handleGradeDropDownModal = (data) => ({
    type: "SET_GRADE_DROPDOWN_MODAL",
    data,
});

const setCMFluencyPassage = (data) => ({
  type: "SET_CM_FLUENCY_PASSAGE",
  data,
});
  
const setRocketLoader = (data) => ({
  type: "SET_CM_ROCKET_LOADER",
  data,
});

const setCMFluencyResult = (data) => ({
  type: "SET_CM_FLUENCY_RESULT",
  data,
});

const setAudioUrl = (data) => ({
  type: "SET_CM_FLUENCY_AUDIO_URL",
  data,
});

const clearReportData = (data) => ({
  type: "CLEAR_REPORT_DATA",
  data,
});


const setReportData = (data) => ({
  type:"SET_REPORT_DATA",
  data
})

const setAudioError = (data) => ({
  type:"SET_CM_FLUENCY_AUDIO_ERROR",
  data
})


  export const mutation = {
    handleGradeDropDownModal,
    setCMFluencyPassage,
    setRocketLoader,
    setCMFluencyResult,
    setAudioUrl,
    setReportData,
    setAudioError,
    clearReportData
  };
  