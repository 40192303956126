import {Api} from "./config/request";

export const getAvailableSchedules = (params) => Api.getFliveRequest(`preference_groups/?${params}`, true);
export const updateSelectedSchedule = (data) => Api.postFliveRequest('preference_groups/assign', data, true);
export const updateStudentProfile = (data, studentId) => Api.putFliveRequest(`children/${studentId}`, JSON.stringify(data), true)
export const getStudentProfile = (studentId) => Api.getFliveRequest(`children/${studentId}`, true);
export const getStudentList = () => Api.getFliveRequest('children', true)
export const listStudentBatches = () => Api.getFliveRequest('batches', true)
export const listClassesForBatch = (batchID, typeOfClass = '', params) => Api.getFliveRequest(`classes?batch_id=${batchID}${typeOfClass}&${params}`, true);
export const getStudentSessionDetails = (sessionId) => Api.getFliveRequest(`/sessions/${sessionId}`, true);
export const getBatchChildren = (batchID) => Api.getFliveRequest(`batches/${batchID}/children`, true)

export const loginWithFreadom = (childId) => Api.postFliveRequest('token/login-with-freadom', {data: {child_freadom_id: childId}}, true);
export const fetchAllAssignments = () => Api.getRequest('assignment/v1/child');
export const updateAssignment = (data) => Api.putRequest(`assignment/v1/child/`, data)

export const Student = {
    getStudentProfile,
    getAvailableSchedules,
    updateSelectedSchedule,
    getBatchChildren,
    updateStudentProfile,
    getStudentList,
    listStudentBatches,
    listClassesForBatch,
    loginWithFreadom,
    getStudentSessionDetails,
    fetchAllAssignments,
    updateAssignment
}
