import React, { useEffect, useState } from "react";
import Epub from 'epubjs';
import _debounce from 'lodash/debounce';
import "./index.scss";

function EpubStoryCard({ storyKey, story, openModal, closeModal }) {
  const [coverUrl, setCoverUrl] = useState(null);

  const fetchCover = async () => {
    // Check if coverUrl is already available in the state
    if (coverUrl) {
      return;
    }

    // Initialize the book
    const book = Epub(story.url);

    try {
      // Load the book and wait for metadata to be available
      await book.loaded.metadata;

      // Get the cover image URL using coverUrl()
      const fetchedCoverUrl = await book.coverUrl();
      console.log(fetchedCoverUrl);

      if (fetchedCoverUrl) {
        setCoverUrl(fetchedCoverUrl);
      } else {
        console.error('No cover found in the book.');
      }
    } catch (error) {
      console.error('Error loading book:', error);
    }
  };

  const debouncedFetchCover = _debounce(fetchCover, 300);

  useEffect(() => {
    debouncedFetchCover();
  }, [story.url, coverUrl]);

  return (
    <div
      className="epub-story-card"
      onClick={() => openModal(storyKey)}
      style={{ height: 'auto' , cursor:'pointer'}}
    >
      {coverUrl ? (
        <img
          src={coverUrl}
          alt="Cover"
          style={{ width: '100%', height: '80%', objectFit: 'contain', borderRadius: '10%', cursor: 'pointer' }}
        />
      ) : (
        <div className="blank-img"></div>
      )}
      
    </div>
  );
}

export default EpubStoryCard;
