import React from "react";
import { connect } from "react-redux";
import "./index.scss";
import { Button } from "antd";
import * as EVENT from "../../../../../utils/eventKeys"

const ReportsCard = (props) => {
  return (
    <div className="osahan-account-page-left shadow-sm bg-white rounded-6 mt-4 rounded border-bottom head-teacher reports-card">
      <div className="p-4 teacher-section">
        <div className="osahan-user text-center">
          <div className="osahan-user-media">
            <div className="osahan-user-media-body">
              <h4>get your results</h4>
              <h6>Monitor your result and improve performance</h6>
              <div
              //FREAD-1342
               id="FLV_RESULT"
               //FREAD-1342
               ><Button 
               //FREAD-1342
               onClick={()=>{
                 props.sendCtEvents(EVENT.FLIVE_SHOW_RESULT);
                 props.onShow();
                 }}
               //FREAD-1342
                 >SHOW RESULT</Button>
               </div>
              <div className="card-icon">
                <GradeIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GradeIcon = () => (
  <svg
    width="95"
    height="109"
    viewBox="0 0 95 109"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M56.1424 19.1977C55.176 16.6109 52.2956 15.2973 49.7088 16.2637L15.5418 29.0279C11.2507 30.6313 9.07156 35.4095 10.6745 39.7009L39.7025 117.402C41.3059 121.694 46.0842 123.873 50.3755 122.27L112.537 99.0473C116.828 97.4439 119.007 92.6656 117.404 88.3743L98.8342 38.6671C97.8678 36.0803 94.9874 34.7667 92.4006 35.7331L70.6876 43.8447C68.1008 44.8111 65.2204 43.4975 64.254 40.9107L56.1424 19.1977ZM63.8522 16.1337C63.2286 14.4645 64.9344 12.8747 66.5556 13.6141L89.7881 24.2091C91.4093 24.9485 91.3273 27.2788 89.6582 27.9024L72.7444 34.2211C71.7097 34.6077 70.5575 34.0822 70.1709 33.0475L63.8522 16.1337ZM74.8921 79.8051L66.0393 84.4353L67.2865 72.7253L74.8921 79.8051ZM88.8025 72.5304L82.6885 75.7279L66.8681 61.0008C65.681 60.0488 64.0805 59.7918 62.6551 60.3243C61.2296 60.8569 60.1896 62.1003 59.9175 63.5974L55.9884 100.484C55.7458 102.762 57.3957 104.805 59.6736 105.047C61.9514 105.29 63.9947 103.64 64.2374 101.362L64.9847 94.3471L81.331 85.7978L88.3673 92.3477C90.0441 93.9086 92.6686 93.8146 94.2295 92.1379C95.7903 90.4612 95.6964 87.8366 94.0196 86.2758L89.1264 81.7207L92.6463 79.8799C94.6758 78.8184 95.4606 76.3127 94.3991 74.2832C93.3377 72.2537 90.832 71.469 88.8025 72.5304Z"
      fill="#ECECEC"
    />
  </svg>
);

export default connect(
  (state) => ({ reduxData: { ...state } }),
  null
)(ReportsCard);

//
