/* Converts an object to param string
 For Ex:
  obj = {
    isActive: true,
    offset: 3,
    search: "name"
  }
  Then the funtion returns => "isActive=true&offset=3&search=name"
*/
const paramObjToString = paramObj => Object.keys(paramObj).map(key => `${key}=${paramObj[key]}`).join("&")

export default paramObjToString;
