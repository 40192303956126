const setUserLoginDetail = data => ({
  type: "SET_USER_DETAIL",
  data
});

const setUserAuthToken = data => ({
  type: "SET_USER_AUTH_TOKEN",
  data
})

const setDefaultChild = data => ({
  type: "SET_DEFAULT_CHILD",
  data,
})

const setChildList = data => ({
  type: "SET_CHILD_LIST",
  data
})
const setBatchID = data => ({
  type: "SET_BATCH_ID",
  data
})
const setGrades = data => ({
  type: "SET_CHILD_GRADES",
  data
})
//FLV-113
const setNavbarFlag = data => ({
  type: "SET_NAVBAR_FLAG",
  data
})
//FLV-113
export const mutation = {
  setUserLoginDetail,
  setUserAuthToken,
  setDefaultChild,
  setChildList,
  setGrades,
  setBatchID,
  //FLV-113
  setNavbarFlag
//FLV-113
};

