import { initialState } from "./state";

export const news = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NEWS_LIST": {
      return Object.assign({}, state, {
        newsList: action.data.newsList,
        has_next: action.data.has_next,
        next_page: action.data.next_page,
        offset: action.data.offset,
        prev_page: action.data.prev_page,
        isLoading: false,
      });
    }
    case "SET_ACTIVE_NEWS_DETAIL": {
      return {
        ...state,
        activeNewsDetail: action.data,
      };
    }
    case "LOAD_NEWS_LIST": {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return state;
  }
};
