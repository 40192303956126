import { Student } from "services/student";
import { mutation } from "./mutations";
import { TeacherApiCalls } from '../../services/teacher';
import {batchIdUpdate} from '../user/actions'

const handleError = err => {
    return err.response || err;
};

// Actions
export const getStudentProfile = (studentId) => async dispatch => {
    try {
        const result = await Student.getStudentProfile(studentId);
        dispatch(mutation.setProfileInfo(result));
        return result;
    } catch (err) {
        handleError(err);
    }
}
export const getAvailableSchedulesRequest = (params = '') => async dispatch => {
    try {
        const result = await Student.getAvailableSchedules(params);
        dispatch(mutation.setAvailableSchedules(result));
        return result;
    } catch (err) {
        handleError(err);
    }
}

export const updateSelectedScheduleRequest = (payload) => async dispatch => {
    try {
        const result = await Student.updateSelectedSchedule(payload)
        let data = {
            data: {
                details: {
                    preferenceGroupId: payload.data.preference_group_id
                }
            }

        }
        dispatch(mutation.setProfileInfo(data));
        return result;
    } catch (err) {
        return handleError(err);
    }
}
export const updateStudentProfile = (profileData, studentId) => async dispatch => {
    try {
        const result = await Student.updateStudentProfile(profileData, studentId)
        dispatch(mutation.setProfileInfo(result));
        return result;
    } catch (err) {
        handleError(err);
    }
}

export const getStudentBatch = (externalID) => async dispatch => {
    try {
        const result = (await Student.getStudentList()).data.find(student => (student.external_id === externalID));
       
        dispatch(mutation.setStudentID(result.id));
        const batch = (await Student.listStudentBatches()).data.filter(batch => batch.children.find(child => child.id === result.id));
        if (batch.length > 0 && batch[0].teacher_id) {
            const teacherInfo = (await TeacherApiCalls.getTeachers("")).data.teachers.find(
                teacher => (teacher.id === batch[0].teacher_id)
            )
            batch[0].batchTeacher = teacherInfo
        }
       
        dispatch(mutation.setStudentBatch(batch));console.log("Reducer >>>",batch)
        dispatch(batchIdUpdate(batch[0]?.batch_id));
        return batch;
    } catch (err) {
        handleError(err);
    }
}

export const getBatchChildrenRequest = (batchId) => async dispatch => {
    try {
        const result = await Student.getBatchChildren(batchId);
        return result?.data ? result.data : result;
    } catch (err) {
        handleError(err);
    }
}

export const getStudentClasses = (batchId, params = '', typeOfClass) => async dispatch => {
    try {
        let prevClasses, upcomingClasses;
        let classesRequests = []
        
        if (!typeOfClass)
            classesRequests.push(
                Student.listClassesForBatch(batchId, '&previous=True', params),
                Student.listClassesForBatch(batchId, '&upcoming=True', params)
            );
        if (typeOfClass === 'previous')
            classesRequests.push(Student.listClassesForBatch(batchId, '&previous=True', params))
        if (typeOfClass === 'upcoming')
            classesRequests.push(Student.listClassesForBatch(batchId, '&upcoming=True', params))

        //TODO: Fix this class_sessions key check
        if (classesRequests?.length > 0) {
            if (!typeOfClass)
                ([prevClasses, upcomingClasses] = await Promise.all(classesRequests));
            if (typeOfClass === 'previous')
                ([prevClasses] = await Promise.all(classesRequests))
            if (typeOfClass === 'upcoming')
                ([upcomingClasses] = await Promise.all(classesRequests));

            dispatch(mutation.setStudentClasses({
                previous: prevClasses?.data || {},
                upcoming: upcomingClasses?.data || {}
            }));
        }
        return [prevClasses?.data || {}, upcomingClasses?.data || {}];
    } catch (err) {
        handleError(err);
    }
}

export const fetchClassDetailsRequest = (sessionId) => async dispatch => {
    try {
        let response = await Student.getStudentSessionDetails(sessionId)
        dispatch(mutation.setSessionInfo(response.data))
        return response;
    } catch (err) {
        return handleError(err);
    }
}
export const fetchAllAssignmentsRequest = () => async dispatch => {
    try {
        const result = await Student.fetchAllAssignments()
        dispatch(mutation.setAssignments(result.data))
        return result;
    } catch (err) {
        return handleError(err);
    }
}
export const updateAssignment = (data) => async dispatch => {
    try {
        const result = await Student.updateAssignment(data)
        return result;
    } catch (err) {
        return handleError(err);
    }
}