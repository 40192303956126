import React, { useState, useEffect } from 'react';
import MobileDetect from "mobile-detect";


/**
 * @function useDeviceDetect
 * @description React Hook to get the device type 
 * @param {any} 
 * @returns {any} device type
 * @author Manish Gupta
 */
export default function useDeviceDetect() {
    const [ deviceType, setDeviceType ] = useState(null);

    const handleWindowResize = () => {
        const md = new MobileDetect(navigator.userAgent);
        if (md.tablet()) {
            setDeviceType("tablet")
          } else if (md.mobile()) {
            setDeviceType("mobile");
          } else {
            setDeviceType("desktop");
        }
        console.log("deviceType======",deviceType,md.tablet());
    }

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, []);

    return [ deviceType ]
}