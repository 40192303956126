import {AttendanceApiCalls} from '../../services/attendance';

const handleError = err => {
    console.log("Error in student action : ", err);
    return err.response || err;
};

// Actions
export const getAttendanceInfo = (classID) => async dispatch => {
    try {
        const result = await AttendanceApiCalls.getAttendanceForClass(classID);
        dispatch({type: 'SET_ATTENDANCE_INFO', data: result.data});
        return result;
    } catch (err) {
        handleError(err);
    }
}
