// import React, { useEffect, useState } from "react";
// import { Modal } from 'antd';
// import "./Modal.scss";
// import { he } from "date-fns/locale";

// const ErrorMessageModal = ({ visible, data, closeModal }) => {
//     const [isTimerCalled, setIsTimerCalled] = useState(false);

//     useEffect(() => {
//         console.log("visible,data", visible, data);
//         let timer;
//         if (visible && !isTimerCalled) {
//             timer = setTimeout(() => {
//                 closeModal(); // Close the modal after 1 second
//                 setIsTimerCalled(true);
//             }, 2000); // 1000 milliseconds = 1 second
//         }
//         return () => clearTimeout(timer); // Clean up the timer if the component unmounts or the modal closes early
//     }, [visible, isTimerCalled, closeModal]);

//     useEffect(() => {
//         // Reset isTimerCalled when the modal closes
//         if (!visible) {
//             setIsTimerCalled(false);
//         }
//     }, [visible]);

//     useEffect(() => {
//         if (visible) {
//             Modal.warning({
//                 title: "Error",
//                 content: data,
//                 style: { backgroundColor: 'white' },
//                 onOk: closeModal,
//             });
//         }
//     }, [visible, data, closeModal]);

    
// };

// export default ErrorMessageModal;




import React, { useEffect } from 'react';
import { Button, Modal } from 'antd';
import { LogarithmicScale } from 'chart.js';
const ErrorMessageModal = (props) => {
//   const [modal, contextHolder] = Modal.useModal();
  const countDown = () => {
    let secondsToGo = 5;
    const instance = Modal.error({
      style: {     backgroundColor : "white",
        width: "416px",
        height: "16%",
        padding: "1%",
        borderRadius: "3%", },
      footer: null,
      title: 'Error:'+props.data,
      content: `Closing within ${secondsToGo} second.`,
      onOk: () => {
        instance.destroy();
      }
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: `Closing within ${secondsToGo} second.`,
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      props.closeModal();
      instance.destroy();
    }, secondsToGo * 1000);
  };

  useEffect (() => {
    console.log("visible",props.visible);
      if(props.visible){
        countDown();
      }
  },[props.visible])
  return null;
};
export default ErrorMessageModal;

