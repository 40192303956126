import React from 'react';
import "./ProgressBar.scss";

const ProgressBar = (props) => {
    return (
        <div className="profile-progress-bar">
            <div className="progress-bar-container">
                <div className="progress-bar-bg"></div>
                <div className="progress-bar-fill" style={{width:`${(props.currentForm*100)/props.numForms}%`}}></div>
            </div>
        </div>
    );
}

export default ProgressBar;