import DefaultUserIcon from "../../../../../assets/image/default-user.png";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { storage as LocalStorage } from "../../../../../services/config/storage";
import "./index.scss";
import { Link } from "react-router-dom";
import { EditIcon } from "../../../../../assets/image/svg";
import * as EVENT from "../../../../../utils/eventKeys";

const UserCard = ({ reduxData, sendAnalytics, ...props }) => {
  const [freadomChild, setFreadomChild] = useState({});
  const [currentChild, setCurrentChild] = useState({});
  let percentage = 65;
  useEffect(() => {
    let freadomData = LocalStorage.fetch.freadomChild();
    let currentChild = LocalStorage.fetch.defaultChild();
    setFreadomChild(freadomData);
    setCurrentChild(currentChild);
    // animateProgressBar();
  }, []);

  // const animateProgressBar = useCallback(() => {
  //   let el = document.getElementById("inner-circle");
  //   let i = 0;
  //   let intervalId;
  //   intervalId = setInterval(function () {
  //     if (i > percentage) {
  //       return clearInterval(intervalId);
  //     }
  //     el.style["stroke-dashoffset"] = `calc(250 - (250 * ${i++}) / 100)`;
  //   }, 10);
  // }, []);

  return (
    <div className="osahan-account-page-left shadow-sm bg-white rounded user-card">
      <div className=" pt-4 pl-3 pr-3 pb-2">
        <div className="osahan-user">
          <div className="osahan-user-media flex flex-row flex-wrap">
            <img
              className="mb-3 rounded-pill shadow-sm mt-1 user-image"
              style={{ height: "70px", objectFit: "cover" }}
              src={freadomChild?.image ? freadomChild?.image : DefaultUserIcon}
              alt="No Student Image"
            />
            <div className="child-detail">
              <>
                <h6 className="mb-1 nametext text-center">
                  {currentChild?.name}
                </h6>
                <h6 style={{ color: "#aeabb3", textAlign: "left" }}>
                  {
                    reduxData?.subscriptions?.subscriptions?.find(
                      (child) =>
                        child.child?.external_id == freadomChild?.external_id
                    )?.pack_details?.label
                  }
                </h6>
              </>
              {/* <div
                className="edit-profile-btn"
                onClick={() => sendAnalytics(EVENT.EDIT_PROFILE)}
              >
                <Link to={"/student/profile/edit"}>Edit Profile</Link>
              </div>

             */}
              <div
                className="edit-profile-btn"
                //FREAD-1342
                id="FLV_EDIT_PROFILE"
                //FREAD-1342
                onClick={() => {
                  //sendAnalytics(EVENT.EDIT_PROFILE)
                  //FREAD-1342
                  props.sendCtEvents(EVENT.FLIVE_EDIT_PROFILE);
                  //FREAD-1342
                }}
              >
                <Link to={"/student/profile/edit"}>
                  Edit Profile
                  <EditIcon className="editIcon" width={13} height={11} />
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            {/* <div className="col-6">
              <div
                className="edit-profile-btn"
                onClick={() => sendAnalytics(EVENT.EDIT_PROFILE)}
              >
                <Link to={"/student/profile/edit"}>
                  Edit Profile
                  <EditIcon className="editIcon" width={13} height={11} />
                </Link>
              </div>
            </div>
            */}
            <div className="col-6">
              <div className="percent">
                <div className="row">
                  {/* <div className="col-6">
                    <svg>
                      <circle cx="10" cy="10" r="10" />
                      <circle id={"inner-circle"} cx="10" cy="10" r="10" />
                    </svg>
                  </div>
                   <div className="col-6">
                     <div className="number">
                      <h2>
                        {percentage}
                        <span className="attendenceclass">%Attendance</span>
                      </h2>
            </div> 
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(
  (state) => ({ reduxData: { ...state } }),
  null
)(UserCard);
