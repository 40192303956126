import React, { useEffect, useState } from "react";
import "./index.scss";
import { Modal, Spin } from "antd";
import {
  BackpackIcon,
  Calender15Icon,
  GradeAIcon,
  TimeIcon,
} from "assets/image/svg.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../../store/user/actions";
import * as studentActions from "../../../../store/student/actions";
import * as attendanceActions from "../../../../store/attendance/actions";
import moment from "moment";
import { CommentIcon, CrossIcon, PdfIcon } from "../../../../assets/image/svg";
import MobileDetect from "mobile-detect";
import { BrowserView, MobileView } from "react-device-detect";
import { Link } from "react-router-dom";
import * as EVENT from "../../../../utils/eventKeys";
import imageIcon from "../../../../assets/image/PdfIcon.svg";

const ClassDetails = (props) => {
  let buttons = null;
  let upcomingPill = null;
  const [classDetails, setClassDetails] = useState({});
  const [attendance, setAttendance] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.visible) {
      (async () => {
        setIsLoading(true);
        let res = await props.fetchClassDetailsRequest(
          props.selectedClassData?.session_id
        );

        if (res) {
          setClassDetails(res.data);
          setIsLoading(false);
        }
      })();
      /*if(props.selectedClassData?.cid) {
                (async () => {
                    setAttendance((await props.getAttendanceInfo(props.selectedClassData.cid)).data.find(att => (att.child.id == props.selectedClassData.studentID)))
                    console.log("ATT FETCHED",);
                })();
            }*/
    }
    return () => {
      setClassDetails({});
    };
  }, [props.visible]);

  const renderAttendanceStatus = (status) => {
    if (!status) {
      return "NONE";
    }
    switch (status) {
      case "UNMARKED":
        return null;
      case "REPEAT":
      case "REPEATED":
        return (
          <button
            className="missed-btn"
            style={{
              color: "#faad14",
              border: "2px solid #faad14",
              background: "white",
              borderRadius: "5px",
            }}
          >
            {status}
          </button>
        );
      case "PRESENT":
        return <button className="attended-btn">{status}</button>;
      case "MISSED":
      case "ABSENT":
        return (
          <button
            className="missed-btn"
            style={{
              color: "#e22222",
              border: "2px solid #e22222",
              background: "white",
              borderRadius: "5px",
            }}
          >
            {status}
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      maskStyle={{
        background: "rgba(0,0,0,0.45)",
        backgroundColor: "rgba(0,0,0,0.45)",
      }}
      visible={props.visible}
      wrapClassName="class-details"
      width={660}
      onCancel={props.onClose}
      closeIcon={
        <i
          aria-label="icon: close"
          class="anticon anticon-close ant-modal-close-icon"
        >
          <CrossIcon />
        </i>
      }
    >
      <div className="shadow-box">
        <div className="d-flex sb subject-title">
          <div className="d-flex mb-10">
            <h1 className="modal-title">
              {props.selectedClassData?.session?.name || ""}
            </h1>
          </div>
          {buttons}
        </div>
        <div className={"modal-data"}>
          <div className="seperator-20" />
          <div className="class-timings d-flex j-c-sb">
            <div className="d-flex timeSection">
              <h3>
                <Calender15Icon
                  style={timingIconStyles}
                  height={16}
                  width={16}
                />
                {getTimeComponents(
                  "date",
                  props.selectedClassData?.event?.start_timestamp,
                  props.selectedClassData?.event?.end_timestamp
                )}
                {" " +
                  getTimeComponents(
                    "month",
                    props.selectedClassData?.event?.start_timestamp,
                    props.selectedClassData?.event?.end_timestamp
                  )}
              </h3>
              <h3>
                <TimeIcon
                  style={timingIconStyles}
                  color="#9134D8"
                  height={16}
                  width={16}
                />
                {getTimeComponents(
                  "timeSlot",
                  props.selectedClassData?.event?.start_timestamp,
                  props.selectedClassData?.event?.end_timestamp
                )}
              </h3>
            </div>
            <div>
              {renderAttendanceStatus(
                props.selectedClassData?.attendanceStatus
              )}
            </div>
          </div>
          <div className="seperator-20" />

          {classDetails?.homework ? (
            <div className="description">
              <div className="icon-text">
                <BackpackIcon width="12" height="12" />
                <h5>HOMEWORK</h5>
              </div>
              {isLoading && (
                <div className={"load-more"}>
                  <Spin />
                  Loading...
                </div>
              )}
              <p
                id={"homework"}
                className="updatedpara"
                dangerouslySetInnerHTML={{
                  __html: classDetails?.homework || "",
                }}
              />

              <BrowserView>
                {classDetails?.previews &&
                  Object.keys(classDetails?.previews)?.map((link) => [
                    <div>
                      {link.includes("web.getfreadom.com") && (
                        <a
                          //FREAD-1342
                          onClick={() => {
                            props.sendCtEvents(
                              EVENT.FLIVE_PREVIOUS_CLASSES_HOMEWORK
                            );
                          }}
                          id="PREVIOUS_HW_BTN"
                          //FREAD-1342
                          className="btn-link"
                          target="_blank"
                          href={link}
                        >
                          <button className=" ant-btn primary-btn banner-btn ant-btn-primary ant-btn-lg homebtn">
                            GO TO HOMEWORK
                          </button>
                        </a>
                      )}
                    </div>,
                  ])}
                {classDetails?.previews &&
                Object.keys(classDetails.previews)?.length > 0
                  ? null
                  : "No links"}
              </BrowserView>
              <MobileView>
                {classDetails?.previews &&
                  Object.keys(classDetails?.previews)?.map((link) => [
                    <div>
                      {link.includes("app.getfreadom.com") && (
                        <a
                          //FREAD-1342
                          onClick={() => {
                            props.sendCtEvents(
                              EVENT.FLIVE_PREVIOUS_CLASSES_HOMEWORK
                            );
                          }}
                          id="PREVIOUS_HW_BTN"
                          //FREAD-1342
                          className="btn-link"
                          target="_blank"
                          href={link}
                        >
                          <button className=" ant-btn primary-btn banner-btn ant-btn-primary ant-btn-lg homebtn">
                            GO TO HOMEWORK
                          </button>
                        </a>
                      )}
                    </div>,
                  ])}
                {classDetails?.previews &&
                Object.keys(classDetails.previews)?.length > 0
                  ? null
                  : "No links"}
              </MobileView>
            </div>
          ) : null}

          {classDetails?.worksheet?.link ? (
            <div className="description">
              <h5 className="icon-text">
                <GradeAIcon width="12" height="12" /> Worksheet
              </h5>
              {isLoading && (
                <div className={"load-more"}>
                  <Spin />
                  Loading...
                </div>
              )}
              <p className="updatedpara">
                <Link
                  className="worksheetlink"
                  to={`/worksheets/${classDetails?.worksheet_id}`}
                >
                  <div>
                    <p>
                      <img
                        src={imageIcon}
                        alt="imageIcon"
                        className="imageIcon"
                      />

                      {classDetails?.worksheet?.title}
                    </p>
                  </div>
                </Link>
              </p>
            </div>
          ) : null}

          {/*  

          <div className="description">
            <h5 className="icon-text">
              <GradeAIcon width="12" height="12" /> Worksheet
            </h5>
            {isLoading && (
              <div className={"load-more"}>
                <Spin />
                Loading...
              </div>
            )}

            <div
              onClick={() => {
                console.log("worksheetid", classDetails.worksheet_id);
                this.props.history.push("/worksheets/" + classDetails.id);
              }}
            >
              <p
                id={"outcome"}
                className="updatedpara"
                dangerouslySetInnerHTML={{
                  __html: classDetails?.learning_outcome || "",
                }}
              />
            </div>
          </div>

        */}

          <div className="description">
            <h5 className="icon-text">
              <GradeAIcon width="12" height="12" /> LEARNING OUTCOME
            </h5>
            {isLoading && (
              <div className={"load-more"}>
                <Spin />
                Loading...
              </div>
            )}
            <p
              id={"outcome"}
              className="updatedpara"
              dangerouslySetInnerHTML={{
                __html: classDetails?.learning_outcome || "",
              }}
            />
          </div>

          <div className="description">
            <h5 className="icon-text">
              <CommentIcon />
              TEACHER'S COMMENT
            </h5>

            <p className="updatedpara2">
              {props.selectedClassData?.attendanceComments &&
              props.selectedClassData?.attendanceComments?.trim() != ""
                ? props.selectedClassData?.attendanceComments
                : "Check your email for Teacher Comments."}
            </p>
          </div>

          <div className="description">
            {/* <h5 className="icon-text">Freadom Links</h5> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

function getTimeComponents(key, startTime, endTime) {
  if (startTime && endTime) {
    if (key === "date") return moment(startTime).utc(true).format("D");
    if (key === "month") return moment(startTime).utc(true).format("MMM");
    if (key === "timeSlot") {
      let start = moment(startTime).utc(true).format("hh:mm A");
      let end = moment(endTime).utc(true).format("hh:mm A");
      return `${start} - ${end}`;
    }
  }
  return "";
}

const timingIconStyles = {
  marginBottom: "4px",
  marginRight: "10px",
};
export default connect(null, (dispatch) =>
  bindActionCreators(
    { ...userActions, ...studentActions, ...attendanceActions },
    dispatch
  )
)(ClassDetails);
