import React, { Component } from "react";
import "./index.scss";
import { storage as LocalStorage } from "services/config/storage";
import "components/ProfileForm/FormField/index";
import { Button, Steps } from "antd";
import AddressForm from "./AddressForm/index";
import SchoolForm from "./SchoolForm/index";
import ParentForm from "./ParentForm/index";
import * as studentActions from 'store/student/actions.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import ClassScheduleForm from "./ClassScheduleForm";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import { Student } from "../../../../services/student";

const NUM_FORMS = 3;
const Step = Steps.Step;

class ProfileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formValues: {
                schedules: null,
                school: null,
                address: null,
                parent: {
                    fatherFormFields: null,
                    motherFormFields: null,
                },
            },
            currentForm: 1,
            currentFormValid: false,
            renderContent: false,
            submitForm: false,
            showPreferenceForm: false,
            num_forms: 3
        };
        this.schedulesFormRef = React.createRef();
        this.schoolFormRef = React.createRef()
        this.addressFormRef = React.createRef()
        this.parentFormRef = React.createRef()
    }

    async componentDidMount() {
        if (!LocalStorage.fetch.authToken()) {
            // this.props.history.push("/login")
            this.props.history.push("/onboarding")
        } else {
            let fliveStudentResponse = (await Student.loginWithFreadom(LocalStorage.fetch.defaultChild().id))
            if(fliveStudentResponse?.status === 404){
                this.props.history.replace("/flive")
            }else{
                this.initProfileFormData()
            }
        }

    }

    async initProfileFormData() {
        await this.props.getStudentProfile(this.props.student.id);
        const freadomChildData = LocalStorage.fetch.freadomChild();
        await this.props.getStudentBatch(freadomChildData?.external_id);
        if (!this.props.match.params?.edit && this.props?.student?.is_onboarded)
            return this.setState({ isFormCompleted: true, renderContent: true })
        if (!this.props.match.params?.edit && !this.props.student.subscription?.preference_group_id)
            this.setState({
                currentForm: 0,
                showPreferenceForm: true,
                num_forms: 4
            })
        this.setState({
            renderContent: true,
            isFormCompleted: false
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params?.edit !== prevProps.match.params?.edit) {
            this.initProfileFormData()
        }
    }

    onSaveFormData(formKey, formFields) {
        const formKeyValue = formKey;
        this.setState((state) => {
            let newState = {
                formValues: {
                    ...state.formValues,
                },
            };
            newState.formValues[formKey] = formFields;

            return {
                ...state,
                ...newState,
            };
        }, () => {
            this.onSubmitForm(formKeyValue);
        });
    }

    onFormValidChange(formValid) {
        this.setState({
            currentFormValid: formValid
        });
    }

    onNextForm() {
        let refKey = `${FORM_NAMES[this.state.currentForm]}FormRef`
        if (!this[refKey]?.isFormValid?.())
            return
        if (!this.state.currentFormValid) {
            if (this.state.currentForm == 3 && (!this.state.formValues.parent.fatherFormFields && !this.state.formValues.parent.motherFormFields)) {
                toast.error('You need to fill either mother details, father details or both');
            }
            return;
        }
        this.onSaveFormData(FORM_NAMES[this.state.currentForm], this[refKey].getFormFields());

    }

    onPrevForm() {
        let context = this;
        let firstPage = this.state.showPreferenceForm ? 0 : 1
        const prevForm = this.state.currentForm == firstPage ? firstPage : this.state.currentForm - 1;
        if (this.state.currentForm <= firstPage)
            return this.props.history.goBack()
        this.setState({
            ...this.state,
            currentForm: prevForm,
        }, () => {
            let refKey = `${FORM_NAMES[context.state.currentForm]}FormRef`;
            context[refKey]?.setFormFields && context[refKey].setFormFields()
        });
    }

    onFormEdit() {
        this.setState({
            currentForm: 1
        })
        this.props.history.replace('/student/profile/edit');
    }

    onSubmitForm(formKeyValue) {
        let valuesToSubmit = {};
        let values = null;
        switch (formKeyValue) {
            case FORM_NAMES[0]:
                values = this.state.formValues.schedules;
                valuesToSubmit.selectedSchedule = values[1].value;
                break;
            case FORM_NAMES[1]:
                values = this.state.formValues.school;
                valuesToSubmit.school_name = values[0].value;
                valuesToSubmit.school_monthly_expense = parseInt(values[1].value);
                valuesToSubmit.program_expectations = values[2].value;
                valuesToSubmit.other_classes = values.slice(3).filter(element => element.value.trim() != '').map(element => (element.value));
                break;
            case FORM_NAMES[2]:
                values = this.state.formValues.address;
                valuesToSubmit.address = values[0].value;
                valuesToSubmit.locality = values[1].value;
                valuesToSubmit.state = values[2].value;
                valuesToSubmit.city = values[3].value;
                valuesToSubmit.pincode = parseInt(values[4].value);
                break;
            case FORM_NAMES[3]:
                //Clearing father details if father form removed else adding the values
                if (this.state.formValues.parent.fatherFormFields) {
                    const values = this.state.formValues.parent.fatherFormFields;
                    valuesToSubmit.father_details = {};
                    valuesToSubmit.father_details.name = values[0].value;
                    valuesToSubmit.father_details.employer_name = values[1].value;
                    valuesToSubmit.father_details.employer_address = values[2].value;
                    valuesToSubmit.father_details.salary_bracket = values[3].value;
                } else {
                    valuesToSubmit.father_details = cloneDeep(this.props.student.father_details);
                    Object.keys(valuesToSubmit.father_details).forEach(key => {
                        valuesToSubmit.father_details[key] = "";
                    });
                }

                //Clearing mother details if mother form removed else adding the values
                if (this.state.formValues.parent.motherFormFields) {
                    const values = this.state.formValues.parent.motherFormFields;
                    valuesToSubmit.mother_details = {};
                    valuesToSubmit.mother_details.name = values[0].value;
                    valuesToSubmit.mother_details.employer_name = values[1].value;
                    valuesToSubmit.mother_details.employer_address = values[2].value;
                    valuesToSubmit.mother_details.salary_bracket = values[3].value;
                } else {
                    valuesToSubmit.mother_details = cloneDeep(this.props.student.mother_details);
                    Object.keys(valuesToSubmit.mother_details).forEach(key => {
                        valuesToSubmit.mother_details[key] = "";
                    });
                }
                break;
            default:
                break;
        }
        if (formKeyValue === FORM_NAMES[0])
            valuesToSubmit = {
                data: {
                    child_id: this.props.student.id,
                    preference_group_id: valuesToSubmit.selectedSchedule
                }
            };
        else
            valuesToSubmit = {
                data: {
                    details: {
                        ...valuesToSubmit,
                    },
                    is_onboarded: this.props.student?.is_onboarded || this.state.currentForm == this.state.num_forms
                    // is_onboarded: false
                }
            }
        this.setState({ loading: true });

        (async () => {
            let res = {};
            try {
                if (formKeyValue === FORM_NAMES[0]) {
                    res = await this.props.updateSelectedScheduleRequest(valuesToSubmit);
                } else {
                    res = await this.props.updateStudentProfile(valuesToSubmit, this.props.student.id);
                }
            } catch (e) {
                this.setState({ loading: false })
            }

            if (res)
                this.setState({
                    ...this.state,
                    loading: false,
                    currentForm: this.state.currentForm + 1,
                    submitForm: (this.state.currentForm == this.state.num_forms + 1) ? true : false,
                    currentFormValid: false,
                })
            else {
                toast.error('Something went wrong!')
                this.setState({
                    loading: false
                })
            }
        })();
    }

    renderProfileForm(forms) {

        return (
            <React.Fragment>
                <div className="student-profile-title-container">
                    {this.state.currentForm !== 1 && <Button
                        onClick={() => {
                            this.onPrevForm();
                        }}
                    >&lt;</Button>}
                    <h1 className="student-profile-title">{this.props.title}</h1>
                </div>
                <hr className="profile-form-title-seperator" />
                <ProgressBar numForms={this.state.num_forms} currentForm={this.state.currentForm} />
                <div className="student-profile-container">
                    <div className="student-profile-sidebar">
                        <Steps direction="vertical"
                            current={this.state.showPreferenceForm ? this.state.currentForm : this.state.currentForm - 1}>
                            {
                                this.state.showPreferenceForm && <Step title="Class Schedule" description="" />
                            }
                            <Step title="School" description="" />
                            <Step title="Address" description="" />
                            <Step title="Parent's Employment" description="" />
                        </Steps>
                    </div>
                    <div className="student-profile-main">
                        <div>
                            <div className="form-steps-info">
                                <p className="step-number">{`STEP ${this.state.showPreferenceForm ? this.state.currentForm + 1 : this.state.currentForm}/${this.state.num_forms}`}</p>
                                <h3 className="step-title">{formInfo[this.state.currentForm]?.title}</h3>
                                <p className="step-description">{formInfo[this.state.currentForm]?.desc}</p>
                            </div>
                            {forms[this.state.currentForm]}
                        </div>
                        <div style={{ justifyContent: this.state.currentForm === 0 ? "flex-end" : "space-between" }}
                            className="form-button-container">
                            {
                                this.state.currentForm !== 0 && <Button
                                    className="back-btn"
                                    onClick={() => {
                                        this.onPrevForm();
                                    }}
                                >
                                    BACK
                                </Button>}
                            <Button
                                loading={this.state.loading}
                                className="next-btn"
                                onClick={() => {
                                    this.onNextForm()
                                }}
                            >
                                {" "}
                                {(this.state.showPreferenceForm ? this.state.currentForm + 1 : this.state.currentForm) == this.state.num_forms
                                    ? "SUBMIT"
                                    : "NEXT"}
                                {" "}
                            </Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        let { student } = this.props;
        const forms = [
            <ClassScheduleForm
                props={this.props}
                currentForm={this.state.currentForm}
                wrappedComponentRef={e => this.schedulesFormRef = e}
                availableSchedules={student.availableSchedules}
                selectedSchedule={student.subscription?.preference_group_id || null}
                onFormValidChange={(formValid) => {
                    this.onFormValidChange(formValid)
                }}
                formValues={this.state.formValues.schedules}
            />,
            <SchoolForm
                wrappedComponentRef={e => this.schoolFormRef = e}
                schoolName={student.school_name}
                monthlyExpenses={student.school_monthly_expense}
                expectedOutcome={student.program_expectations}
                otherClasses={student.other_classes}
                onFormValidChange={(formValid) => {
                    this.onFormValidChange(formValid)
                }}
                formValues={this.state.formValues.school}
            />,
            <AddressForm
                wrappedComponentRef={e => this.addressFormRef = e}
                address={student.address}
                locality={student.locality}
                pincode={student.pincode}
                state={student.state}
                city={student.city}
                formValues={this.state.formValues.address}
                onFormValidChange={(formValid) => {
                    this.onFormValidChange(formValid)
                }}
            />,
            <ParentForm
                wrappedComponentRef={e => this.parentFormRef = e}
                formValues={this.state.formValues.parent}
                fatherDetails={student.father_details}
                motherDetails={student.mother_details}
                onFormValidChange={(formValid) => {
                    this.onFormValidChange(formValid)
                }}
            />,
        ];
        let formContent = null;
        if (!this.state.isFormCompleted && this.state.currentForm <= this.state.num_forms) {
            formContent = this.renderProfileForm(forms);
        } else {
            //FREAD-934
            this.props.history.replace('/profile-complete')
        }
        return <div className="student-profile-form-content">
            {(this.state.renderContent) ? formContent : null}
        </div>;
    }
}

const FORM_NAMES = {
    0: 'schedules',
    1: 'school',
    2: 'address',
    3: 'parent'
};

const formInfo = [


    {
        title: "Class Schedule",
        desc: "Select a class schedule from Below"
    },
    {
        title: "School and Beyond",
        desc: "About your child’s school and Expectations"
    },
    {
        title: "Address",
        desc: "Where you and your child lives"
    },
    {
        title: "Parent’s Employement",
        desc: "Details about where the child’s parents work"
    }
]

const connectedComponent = connect(
    ({ student,user }) => ({ student: { ...student },user }),
    dispatch => bindActionCreators({ ...studentActions, }, dispatch)
)(ProfileForm);

export default withRouter(connectedComponent);
