import React, { Component } from "react";
import { Button,Card, Avatar,Form, Input, PhoneInput , message, Menu } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as activityActions from "store/activities/actions";
import * as userActions from 'store/user/actions'
import EmailVerified from "assets/image/email-verified.png";
import OtpInput from "react-otp-input";
import CloseBlackIcon from "assets/image/close-black.svg";
import "./ParentProfile.scss";
import {Analytics} from '../../services/analytics'
import * as EVENT from '../../utils/eventKeys'
import { storage as LocalStorage } from "services/config/storage";
const { Meta } = Card;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const otpBoxStyle = {
  // width: '100%',
  width: "45px",
  textAlign: "center",
  height: "45px",
  outline: "none",
  fontSize: "24px",
  border: "1px solid rgb(238, 238, 238)",
  borderRadius: "10px"
};

class ParentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentData: LocalStorage.fetch.parentData(),
      emailView:false,
      error:null,
      email:null,
      otp:null,
      otpSent:false,
      isVerified:false
    };
  }




  async componentDidMount() {
    let is_teacher = this.state.parentData?.is_teacher;
    console.log("is_teacher", is_teacher);
    let parent_data = LocalStorage.fetch.parentData()
   this.setState({ parentData: {...parent_data, is_teacher} });
    //FREAD-1233
    console.log("parent data:", this.state.parentData);
  }
  

  sendAnalytics = (event,data)=>{
    let payload ={} 
    payload[EVENT.SESSION_ID]=LocalStorage.fetch.sessionData()?.session_data 
    payload[EVENT.TIMESTAMP]= new Date();
    payload[EVENT.CHILD_ID]=LocalStorage.fetch.defaultChild()?.id
    payload[EVENT.CHILD_LEVEL]=LocalStorage.fetch.defaultChild()?.grade_level
    payload[EVENT.VIEW_ID]=this.state.viewId

    Analytics.logEvents(event,payload);
  }

  handleUpdateState = (key, e) => {
    if(key == 'email'){
      this.setState({error:null})
    }
    this.setState({[key]: e.target.value});
  };

  submitProfile=async (e)=>{
    e.preventDefault();
    if(this.state.email && this.validateEmail(this.state.email)){
      this.props.form.validateFields(async (err, values) => {
        if(!err){
          this.setState({isSendingOtp:true,disabledButton:true,error:null})
          let reqObject ={
            email:this.state.email
          }
          let res =await this.props.getEmailOtpAction(reqObject)
          console.log(res)
          if(res.result){
            this.setState({otpSent:true,emailView:false,disabledButton:false,isSendingOtp:false})
          }else if(res.data && res.data.error){
            
            this.setState({error:res.data.error.error_detail.email[0],disabledButton:false,isSendingOtp:false})
          }else{
            this.setState({disabledButton:false,isSendingOtp:false})
          }
        }
      })
    }else{
      this.setState({error:"Please enter a valid email address"})
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  verifyOtp=async (e)=>{
    e.preventDefault();
    if(this.state.otp){
      this.props.form.validateFields(async (err, values) => {
        if(!err){
          this.setState({isSendingOtp:true,disabledButton:true})
          let reqObject ={
            email:this.state.email,
            otp:this.state.otp
          }
          let res = await this.props.verifyEmailOtpAction(reqObject)
          console.log(res)
          if(res && res.is_email_verified){
            this.setState({otpSent:false,emailView:false,isVerified:true,disabledButton:false,isSendingOtp:false,parentData:res})
          }else if(res.error && res.error.error_code == 1002){
            this.setState({error:"Invalid OTP. Please try again.",disabledButton:false,isSendingOtp:false})
          }
        }
      })
    }else{
      this.setState({error:"Invalid OTP. Please try again."})
    }
  }

  render() {
    const {getFieldDecorator, getFieldsError} = this.props.form;
    let {parentData,email,emailView,otpSent,otp,error,isVerified} = this.state;
    let childData = LocalStorage.fetch.defaultChild()
    return (
      <div className="component-profile">
        {this.props.fromLink == 'profileSetting'? 
        <div className="description-card">
          <div className="flex">
            {parentData ?parentData.is_teacher ? <p className="total-activities-text">TEACHER PROFILE</p> : <p className="total-activities-text">PARENT PROFILE</p> : null}
            {/* <p className="total-activities-text">
              PARENT PROFILE
            </p> */}
            <img
              src={CloseBlackIcon}
              className="close-icon"
              alt="close"
              onClick={this.props.close}
            />
          </div>
          
          <div className="description-container">
            <Meta
              avatar={parentData?.image ? <Avatar src={parentData?.image} /> :  <Avatar className="child-image">{childData?.name[0]}</Avatar>}
              title={
                parentData && parentData.name && !parentData.is_teacher 
    ? parentData.name 
    : parentData && parentData.name && parentData.is_teacher 
      ? parentData.name.slice(0, -9) 
      : null
              } 
            
              description={<div>
                {parentData?.is_email_verified && parentData?.email?  <div style={{marginBottom:8}}>{parentData?.email}</div> : null}
                <p>{parentData?.country_code}-{parentData?.contact_no}</p>
              </div>}
            />
            
            
            { emailView? 
              <Form layout="vertical" onSubmit={this.submitProfile} >
              <div>EMAIL VERIFICATION</div> 
              <p>Enter your email address below to receive an OTP for verification</p>
              <Form.Item style={{margin:0}}>
                <Input
                    className={'input-class'}
                    size="default"
                    value={email}
                    placeholder="Your email ID"
                    onChange={(e) => this.handleUpdateState("email", e)}
                />
              </Form.Item>
              <div style={{color:'red',fontSize: 14}}>{this.state.error}</div>
              <Button
                  type="primary"
                  disabled={hasErrors(getFieldsError())}
                  className="primary-btn"
                  htmlType="submit"
                  disabled={this.state.disabledButton}
                  loading={this.state.isSendingOtp}
              >
                  CONTINUE
              </Button>
          </Form>:null}

          {otpSent?
              <form onSubmit={this.verifyOtp} >
                  <div>OTP SENT</div> 
                  <p>Enter the OTP below to verify your email address</p>
                  <div style={{marginBottom:24}}>
                  <OtpInput
                      onChange={(otp) => this.setState({otp: otp,error:null})}
                      value={this.state.otp}
                      numInputs={4}
                      //FREAD-963
                      isInputNum={true}
                      containerStyle={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '300px',
                          marginBottom: '10px'
                      }}
                      separator={<span></span>}
                      inputStyle={otpBoxStyle}
                      isDisabled={!otpSent}
                  />
                  <div style={{color:'red',fontSize: 14}}>{this.state.error}</div>
                  </div>
                  <Button
                      type="primary"
                      className="primary-btn"
                      disabled={!otpSent}
                      htmlType="submit"
                  >
                  CONTINUE
                  </Button>
                  
          </form>
          :null}

            {!parentData?.is_email_verified?
              !isVerified && !otpSent && !emailView?
                <Button
                  type="primary"
                  className="primary-btn"
                  size="large"
                  onClick={()=>{this.setState({emailView:true})}}
                >
                  VERIFY YOUR EMAIL
                </Button>:
                null
            :null}
          </div>
          {isVerified?
          <div className="success-container">
            <img src={EmailVerified} width={80}></img>
            EMAIL VERIFICATION COMPLETE
          </div>:null}
        </div>:

        <div className="description-card">
          <div className="flex">
            <span style={{color:'#121118',fontWeight:500, fontSize:18}}>
              VERIFY YOUR EMAIL ADDRESS TO CONTINUE TO 
            </span>
            <p style={{color:'#121118',fontWeight:500,fontSize:18}}>THE <span style={{color:'#9134D8',}}>FREADOM</span> COMMUNITY</p>
            <img
              src={CloseBlackIcon}
              className="close-icon"
              alt="close"
              onClick={this.props.close}
            />
          </div>
          
          <div className="description-container">
            { !isVerified && !otpSent? 
              <Form layout="vertical" onSubmit={this.submitProfile} >
              <div>EMAIL VERIFICATION</div> 
              <p>Enter your email address below to receive an OTP for verification</p>
              <Form.Item style={{margin:0}}>
                <Input
                    className={'input-class'}
                    size="default"
                    value={email}
                    placeholder="Your email ID"
                    onChange={(e) => this.handleUpdateState("email", e)}
                />
              </Form.Item>
              <div style={{color:'red',fontSize: 14}}>{this.state.error}</div>
              <Button
                  type="primary"
                  disabled={hasErrors(getFieldsError())}
                  className="primary-btn"
                  htmlType="submit"
                  disabled={this.state.disabledButton}
                  loading={this.state.isSendingOtp}
              >
                  CONTINUE
              </Button>
          </Form>:null}

            {!isVerified && otpSent?
              <form onSubmit={this.verifyOtp} >
                  <div>OTP SENT</div> 
                  <p>Enter the OTP below to verify your email address</p>
                  <div style={{marginBottom:24}}>
                  <OtpInput
                      onChange={(otp) => this.setState({otp: otp,error:null})}
                      value={this.state.otp}
                      numInputs={4}
                      //FREAD-963
                      isInputNum={true}
                      containerStyle={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '300px',
                          marginBottom: '10px'
                      }}
                      separator={<span></span>}
                      inputStyle={otpBoxStyle}
                      isDisabled={!otpSent}
                  />
                  <div style={{color:'red',fontSize: 14}}>{this.state.error}</div>
                  </div>
                  <Button
                      type="primary"
                      className="primary-btn"
                      disabled={!otpSent}
                      htmlType="submit"
                  >
                  CONTINUE
                  </Button>
              </form>
            :null}
          </div>

          {isVerified?
          <div className="success-container-2">
            <div className="success-inner">
              <img src={EmailVerified} width={50} height={50}></img>
              <div style={{marginLeft:25}}>
                <p>EMAIL VERIFICATION COMPLETE</p>
                <div>YAY! You are all set</div>   
                <div>Head on to the FREADOM Community</div>
              </div> 
            </div>
            <br/>
            <Button
              type="primary"
              className="primary-btn"
              onClick={this.props.goToCommunity}
            >
            COMMUNITY
            </Button> 
          </div>:null}
        </div>}
    </div>
    );
  }
}


const connectedComponent = connect(
  ({user}) => ({...user}),
  (dispatch) => bindActionCreators({...userActions,...activityActions}, dispatch)
)(ParentProfile);

export default Form.create({name: "Profile_Form"})(connectedComponent);