import { Button } from 'antd'
import React from 'react'
import { MdOutlineClose } from "react-icons/md";
import './meaningModalStyles.scss'


const MeaningModal = ({ data, closeModal }) => {
    // const meanings =data &&  data[1]?.value?.split('\n')
    return (
        <div className='meaning_modal_container'>
            {
                data &&
                <>
                    <div className='meaning_modal_header'>
                        <p>{`Meaning of ${data[0]?.value} `}</p>
                        <MdOutlineClose onClick={() => closeModal()} className='close_icon' />
                    </div>

                    <div className='meaning_modal_definition'>
                        {
                            data.map((meaningObj, index) => (
                                <div className='meaning_container' key={index}>
                                    <p className='meaning_key'>{`${meaningObj?.key}: `}</p>
                                    <div className='meaning_value_container'>{meaningObj?.value?.split('\n').map((val,ind) => (
                                        <p key={ind} className='meaning_value'>{val}</p>
                                    )
                                    )}</div>
                                </div>
                            ))
                        }
                    </div>

                </>
            }
        </div>
    )
}

export default MeaningModal