import moment from "moment";

export function getTimeComponents(key, startTime, endTime) {
    if (startTime && endTime) {
        if (key === 'date')
            return moment(startTime).utc(true).format('D');
        if (key === 'month')
            return moment(startTime).utc(true).format('MMM');
        if (key === 'timeSlot') {
            let start = moment(startTime).utc(true).format('hh:mm A')
            let end = moment(endTime).utc(true).format('hh:mm A')
            return `${start} - ${end}`
        }
    }
    return [];
}
