const setNewsList = (data) => ({
  type: "SET_NEWS_LIST",
  data,
});

const setActiveNewsDetails = (data) => ({
  type: "SET_ACTIVE_NEWS_DETAIL",
  data,
});
const loadNewsList = () => ({
  type: "LOAD_NEWS_LIST",
});

export const mutation = {
  setNewsList,
  setActiveNewsDetails,
  loadNewsList,
};
