import { Button } from 'antd'
import React from 'react'
import { IoMdClose } from "react-icons/io";

const ShowInfoModal = ({ onok,closeModal,onClickHere }) => {
  return (
   <div className="info_modal_container">
      <div className="info_modal_header">
        <p>Important Instruction</p>
        <IoMdClose className="close_icon" onClick={() => closeModal()} />
      </div>
      <div className="info_modal_body">
        <p>Dear Teacher,</p>
        <p>Kindly verify the student list to confirm all students are registered prior to assigning tasks. Click &nbsp;
           <span onClick={()=>onClickHere()}>
           here
          </span>
           &nbsp;
           to verify.</p>
      </div>

      <div className="info_modal_footer">
          <Button
              type="primary"
              className="primary-btn ml-16"
              size="large"
              onClick={() => onok()}
          >
              Verified
          </Button>
      </div>
    </div>
  )
}

export default ShowInfoModal