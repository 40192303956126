import { newsServices } from "services/News";
import { mutation } from "./mutations";

const handleError = (err) => {
  console.log("Error in Login action : ", err);
};

// Actions
export const getNewsListRequest = (offset) => async (dispatch) => {
  try {
    const result = await newsServices.getNewsList(offset);
    dispatch(mutation.loadNewsList());
    dispatch(
      mutation.setNewsList({
        newsList: result?.result || [],
        has_next: result?.has_next || false,
        next_page: result?.next_page || 0,
        offset: result?.offset || 0,
        prev_page: result?.prev_page || 0,
      })
    );
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getNewsMcqRequest = (id) => async (dispatch) => {
  try {
    const result = await newsServices.getNewsMcq(id);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const submitNewsQuizAnswerRequest = (payload) => async (dispatch) => {
  try {
    const result = await newsServices.submitNewsQuizAnswer(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getActiveNewsDetailRequest = (unit_id) => async (dispatch) => {
  try {
    const result = await newsServices.getNewsDetail(unit_id);
    dispatch(mutation.setActiveNewsDetails(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};
export const createStreakRequest = (payload) => async (dispatch) => {
  try {
    const result = await newsServices.createStreak(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};
