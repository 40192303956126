const setFeedResult = (data) => ({
  type: "SET_FEED_RESULT",
  data,
});
const updateBookmarkedFeed = (data) => ({
  type: "SET_BOOKMARK_FEED_RESULT",
  data
});
const setStartSessionTime = () => ({
  type: "SET_START_SESSION_TIME",
});

export const mutation = {
  updateBookmarkedFeed,
  setFeedResult,
  setStartSessionTime
};