import { mutation } from "./mutations";
import { TeacherApiCalls } from "../../services/teacher";
import { User } from "services/user";

const handleError = (err) => {
  return err.response || err;
};

// Actions
export const getGlobalSearch = (query) => async (dispatch) => {
  try {
    const result = await TeacherApiCalls.getSearchResult(query);
    dispatch(mutation.setSearchResult(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getAllLevels = () => async (dispatch) => {
  try {
    const result = await TeacherApiCalls.getLevels();
    dispatch(mutation.setLevels(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getAllClassMates = () => async (dispatch) => {
  try {
    const result = await TeacherApiCalls.getClassmates();
    dispatch(mutation.setClassMates(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const createAssignment = (payload) => async (dispatch) => {
  try {
    const result = await TeacherApiCalls.createAssignment(payload);
    await dispatch(fetchAllAssignments());
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const fetchAllAssignments = () => async (dispatch) => {
  try {
    const result = await TeacherApiCalls.getAllAssignments();
    dispatch(mutation.setAllAssignment(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const deactivateAssignment = (payload) => async (dispatch) => {
  try {
    const result = await TeacherApiCalls.deactivateAssignment(payload);
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const getGPUnits = (params) => async (dispatch) => {

  try {
    const result = await TeacherApiCalls.getGuidedPathUnits(params);

    dispatch(mutation.setGuidedPathUnits(result));
    return result;
  } catch (err) {
    handleError(err);
  }
};

export const updateGPUnit = (data,params)=>async(dispatch)=>{
    try{
        const result = await TeacherApiCalls.updateGuidedPathUnit(data)
        await dispatch(getGPUnits(params));
        return result
    }catch(err){
        handleError(err)
    }
}