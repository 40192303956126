/* eslint-disable jsx-a11y/anchor-is-valid */
import { storage as LocalStorage } from "services/config/storage";
import React, { Component } from "react";
import Navbar from "components/Navbar";
import "./index.scss";
import { Tab, Tabs } from "react-bootstrap-tabs";
import { Accordion, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../store/user/actions";
import * as studentActions from "../../store/student/actions";
import * as attendanceActions from "../../store/attendance/actions";
import * as subscriptionsActions from "../../store/subscriptions/actions";
import * as announcementActions from "../../store/announcements/actions";
import * as homeworkActions from "../../store/Homework/actions";
import * as testActions from "../../store/tests/actions";
import ClassDetails from "./components/ClassDetails";
import ChildrenModal from "../../components/ChildrenModal";
import moment from "moment";
import AnnouncementModal from "../../components/AnnouncementModal";
import { NextClass } from "./components/Cards/NextClass";
import { getTimeComponents } from "./utils";
import TeacherStudentCard from "./components/Cards/TeacherStudentCard";
import UserCard from "./components/Cards/UserCard";
import AnnouncementsCard from "./components/Cards/AnnouncementsCard";
import { generateFilterUrl } from "../../utils/commonUtils";
import isEmpty from "lodash/isEmpty";
import CalendarNext from "../../assets/image/calendar-next.svg";
import CalendarPrev from "../../assets/image/calendar-previous.svg";
import { Spin } from "antd";
import ReportsCard from "./components/Cards/ReportsCard";
import BatchSelector from "./components/BatchSelector";
import RemainingClassCard from "./components/Cards/RemainingClassCard";
import CustomerSupportModal from "../../components/CustomerSupportModal";
import TestsModal from "../../components/TestsModal";
import * as EVENT from "../../utils/eventKeys";
import { Analytics } from "../../services/analytics";
import questionIcon from "../../assets/image/question-icon.png";

const CLASS_JOIN_BUFFER_SECONDS = 1800;

class HomeworksPopUp extends Component {
  state = {};

  constructor(props, context) {
    super(props, context);

    this.state = {
      activeKey: "1",
      showDetailsModal: false,
      freadom_data: LocalStorage.fetch.freadomChild(),
      parent_data: LocalStorage.fetch.parentData(),
      auth: LocalStorage.fetch.authToken(),
      typeOfClass: "upcoming",
      previousClasses: [],
      upcomingClasses: [],
      filters: {
        upcoming: {
          offset: 0,
        },
        previous: {
          offset: 0,
        },
      },
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  async componentDidMount() {
    if (!LocalStorage.fetch.authToken()) {
      this.props.history.push("/onboarding", {
        urlToRedirect: this.props.location.pathname,
      });
    } else {
      await this.props.getStudentBatch(this.state.freadom_data?.external_id);
      setInterval(async () => {
        await this.getClasses();
      }, 30000);

      this.getClasses();

      // const [prevClasses, upcomingClasses] = await this.props.getStudentClasses(this.props.reduxData.student?.batchDetails);
      //await this.props.getAttendanceInfo(prevClasses?.map(c => c.id));
      await this.props.getSubscriptionsInfo(this.state.freadom_data.parent);
      await this.props.clearAnnouncements();

      this.props.reduxData?.student?.batchDetails[0]?.batch_id &&
        (await this.props.getAnnouncements(
          this.props.reduxData?.student?.batchDetails[0]?.batch_id
        ));

      // this.props.reduxData?.student?.batchDetails[0]?.batch_id &&
      //   (await this.props.getHomework(
      //     this.props.reduxData?.student?.batchDetails[0]?.batch_id
      //   ));

      this.props.getHomework(
        this.props.reduxData?.student?.batchDetails[0]?.batch_id
      );

      await this.props.getAllTests(this.props.reduxData?.student?.crm_lsq_id);
      // const isBatchStarted = this.props?.reduxData?.student?.is_onboarded && (this.props?.reduxData?.student?.batch?.batch_id || this.props?.reduxData?.student?.batchDetails?.length > 0);
      // if (!isBatchStarted) {
      //     this.props.history.push('/student/profile');
      // }
      //this.handleStickyClass('add')

      await this.sendAnalytics(EVENT.VIEW_UPDATES);
      await this.handleStickyClass("remove");
      await this.setState({ announcementsModalVisible: true });
    }
    window.addEventListener("popstate", (e) => {
      //this.props.history.push("/");
    });
  }
  sendAnalytics = (event, data = {}) => {
    let payload = {};
    if (Object.keys(data).length > 0) {
      payload[EVENT.SESSION_TIME] = data.time;
      payload[EVENT.SESSION_DATE] = data.dat;
      payload[EVENT.SESSION_TITLE] = data.name;
      payload[EVENT.BUTTON_LOCATION] = data.button ? data.button : null;
    }
    payload[EVENT.SESSION_ID] = LocalStorage.fetch.sessionData()?.session_data;
    payload[EVENT.TIMESTAMP] = new Date();
    payload[EVENT.PLATFORM] = "Web";
    payload[EVENT.PHONE_NUMBER] = LocalStorage.fetch.defaultChild()?.contact_no;
    payload[EVENT.EMAIL] = LocalStorage.fetch.defaultChild()?.email;
    payload[EVENT.CHILD_ID] = LocalStorage.fetch.defaultChild()?.id;
    payload[EVENT.CHILD_LEVEL] = LocalStorage.fetch.defaultChild()?.grade_level;

    Analytics.logEvents(event, payload);
  };

  getClasses = async (typeOfClass, batchToFetchFor) => {
    let filters = { ...this.state.filters };
    let resPrevious = {},
      resUpcoming = {},
      urlParams = "";
    this.setState({
      isLoading: true,
    });

    let batchId =
      batchToFetchFor ||
      this.props.reduxData.student?.batchDetails?.[0]?.batch_id;
    switch (typeOfClass) {
      case "previous":
        if (this.props.reduxData.student?.classes?.previous?.has_next) {
          urlParams = generateFilterUrl(filters.previous);
          [resPrevious] = await this.props.getStudentClasses(
            batchId,
            urlParams,
            "previous"
          );
          this.isFetchingMoreData = false;
        }
        break;
      case "upcoming":
        if (this.props.reduxData.student?.classes?.upcoming?.has_next) {
          urlParams = generateFilterUrl(filters.upcoming);
          [{}, resUpcoming] = await this.props.getStudentClasses(
            batchId,
            urlParams,
            "upcoming"
          );
          this.isFetchingMoreData = false;
        }
        break;
      default:
        urlParams = generateFilterUrl({
          offset: 0,
        });
        [resPrevious, resUpcoming] = await this.props.getStudentClasses(
          batchId,
          urlParams,
          null
        );
        this.isFetchingMoreData = false;
        break;
    }
    this.setState({
      isLoading: false,
      resPrevious,
      resUpcoming,
      previousClasses: !isEmpty(resPrevious)
        ? [
            //...this.state.previousClasses,
            ...resPrevious?.class_sessions.reverse(),
          ]
        : [...this.state.previousClasses.reverse()],
      upcomingClasses: !isEmpty(resUpcoming)
        ? [
            //...this.state.upcomingClasses,
            ...resUpcoming?.class_sessions,
          ]
        : [...this.state.upcomingClasses],
    });
  };

  handleStickyClass(operation) {
    let tabs = document.getElementsByClassName("nav nav-tabs");
    if (tabs && tabs?.length > 0) {
      if (operation === "add") tabs[0].classList.add("sticky");
      else tabs[0].classList.remove("sticky");
    }
  }

  // shouldJoinClass = (nextClassStartTime) => {
  //   const currentDate = moment().utc(true);
  //   let nextClassTime = moment(nextClassStartTime).utc(true).add(5, "minutes");
  //   let currentTime = currentDate;
  //   //if next class time is under 30mins && add 5min late time
  //   return (
  //     currentTime.valueOf() <= nextClassTime.valueOf() &&
  //     nextClassTime.diff(currentTime, "seconds") <= CLASS_JOIN_BUFFER_SECONDS
  //   );
  // };

  shouldJoinClass = (nextClassStartTime, sessionClassName) => {
    const currentDate = moment().utc(true);
    let nextClassTime = moment(nextClassStartTime).utc(true);

    let currentTime = currentDate;

    if (Math.abs(nextClassTime.diff(currentTime, "seconds")) <= 1800) {
      return true;
    } else return false;
  };

  renderAttendanceStatus(status) {
    if (!status) {
      return "NONE";
    }
    switch (status) {
      case "UNMARKED":
        return null;
      case "REPEAT":
      case "REPEATED":
        return <button className="repeat-btn">{status}</button>;
      case "ATTENDED":
      case "PRESENT":
        return <button className="attended-btn">{status}</button>;
      case "MISSED":
      case "ABSENT":
        return <button className="missed-btn">{status}</button>;
      default:
        return null;
    }
  }
  joinClass = (c) => {
    // console.log("c>>>>", c);
    this.sendAnalytics(EVENT.VIEW_UPCOMING_CLASS, {
      time: getTimeComponents(
        "timeSlot",
        c.event.start_timestamp,
        c.event.end_timestamp
      ),
      date:
        getTimeComponents(
          "date",
          c.event.start_timestamp,
          c.event.end_timestamp
        ) +
        " " +
        getTimeComponents(
          "month",
          c.event.start_timestamp,
          c.event.end_timestamp
        ),
      name: c.session?.name,
      button: "upcoming class view",
    });
    window.open(c.class_link, "_blank");
  };

  showPtmTag = () => Math.random() * 100 > 50; //Add here the logic to check for PTM key in API response

  renderClassList = (classes, isUpcomingClass) => {
    // console.log("classes<<<<", classes);
    return classes?.map((c, index) => {
      // console.log("c<<<<", c);
      return (
        <div className="tabContent">
          <div
            className={`tab-pane tabPane ${isUpcomingClass ? "upcoming" : ""}`}
          >
            <input
              type="radio"
              id={`control_${index + 1}`}
              name="select"
              value={index + 1}
              checked
            />
            <label htmlFor={`control_${index + 1}`}>
              <div className="bg-white card mb-4 labelTo">
                <div
                  className={
                    c.session.label == null || c.session.label === false
                      ? "hide"
                      : "ptmLabelClass"
                  }
                >
                  PTM
                </div>
                <div
                  className="gold-members p-4"
                  onClick={() => {
                    if (!isUpcomingClass) {
                      this.handleStickyClass("remove");
                      this.sendAnalytics(EVENT.VIEW_PREVIOUS_CLASS, {
                        time: getTimeComponents(
                          "timeSlot",
                          c.event.start_timestamp,
                          c.event.end_timestamp
                        ),
                        date:
                          getTimeComponents(
                            "date",
                            c.event.start_timestamp,
                            c.event.end_timestamp
                          ) +
                          " " +
                          getTimeComponents(
                            "month",
                            c.event.start_timestamp,
                            c.event.end_timestamp
                          ),
                        name: c.session?.name,
                      });
                      this.setState({
                        showDetailsModal: true,
                        selectedClassData: {
                          ...c,
                          attendanceStatus: c.attendance.attendance_status,
                          attendanceComments: c.attendance.attendance_comments,
                          classTime: { ...c.events },
                          cid: c?.id,
                          studentID: this.props.reduxData?.student?.id,
                          classInfo: c,
                        },
                      });
                    }
                  }}
                >
                  <div className="media">
                    <div className="updatedate text-center ">
                      <span className="float-right d-block dateTitle">
                        {getTimeComponents(
                          "date",
                          c.event.start_timestamp,
                          c.event.end_timestamp
                        ) +
                          " " +
                          getTimeComponents(
                            "month",
                            c.event.start_timestamp,
                            c.event.end_timestamp
                          )}
                      </span>
                      <p className=" mb-1 d-block timeTitle">
                        {getTimeComponents(
                          "timeSlot",
                          c.event.start_timestamp,
                          c.event.end_timestamp
                        )}
                      </p>
                    </div>
                    <div className="media-body">
                      <div
                        className={
                          isUpcomingClass
                            ? "upcoming flex-wrap"
                            : "previous flex-wrap"
                        }
                      >
                        <div className="name-desc-width">
                          <h6 className="mb-1">{c.session?.name}</h6>
                          <p className="mb-0">{c.session?.description}</p>
                        </div>
                        {!isUpcomingClass && (
                          <div>
                            {this.renderAttendanceStatus(
                              c.attendance.attendance_status
                            )}
                          </div>
                        )}
                        {isUpcomingClass &&
                        this.shouldJoinClass(c.event.start_timestamp) ? (
                          <div style={{ padding: "0 1px" }}>
                            <Button
                              title="Join Class"
                              className={"class-join-btn"}
                              onClick={() => {
                                this.joinClass(c);
                              }}
                            >
                              JOIN
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
      );
    });
  };

  onScrollClasses = (event) => {
    if (
      window.innerHeight < window.innerWidth &&
      (Math.ceil(event.target.scrollHeight - event.target.scrollTop) ===
        event.target.clientHeight ||
        Math.floor(event.target.scrollHeight - event.target.scrollTop) ===
          event.target.clientHeight) &&
      !this.isFetchingMoreData
    ) {
      this.handlePageChange();
    }
  };

  handlePageChange = () => {
    this.isFetchingMoreData = true;
    let { typeOfClass } = this.state;
    let nextPage = "";
    if (typeOfClass === "previous")
      nextPage = this.props.reduxData.student?.classes?.previous?.next_page;
    else nextPage = this.props.reduxData.student?.classes?.upcoming?.next_page;
    // this.setState(
    //   {
    //     filters: {
    //       ...this.state.filters,
    //       [typeOfClass]: {
    //         offset: nextPage,
    //       },
    //     },
    //   },
    //   () => {
    //     this.getClasses(typeOfClass);
    //   }
    // );
  };
  setClassType = async (typeOfClass) => {
    if (typeOfClass === 0) {
      this.sendAnalytics(EVENT.VIEW_UPCOMING_CLASS_LIST);
      typeOfClass = "upcoming";
    } else {
      this.sendAnalytics(EVENT.VIEW_PREVIOUS_CLASS_LIST);
      typeOfClass = "previous";
    }

    if (typeOfClass)
      this.setState({
        typeOfClass,
        filters: {
          upcoming: {
            offset: 0,
          },
          previous: {
            offset: 0,
          },
        },
      });
  };

  render() {
    //Bringing classes from all batches in a single array
    /** assuming that single child is on single batch only. If child-batch relation is 1*n then uncomment and handle accordingly */
    // let expandedClasses = [];
    // if (this.props.reduxData.student.classes) {
    //     this.props.reduxData.student.classes.forEach(element => {
    //         expandedClasses = [...expandedClasses, ...element];
    //     })
    // }

    //Current date : used to determine upcoming and previous classes in later code

    //Next class info to be displayed
    const nextClassToAttend = this.state.upcomingClasses?.[0];
    let nextClassTime = {
      classStartDateTime: nextClassToAttend?.event?.start_timestamp,
      classEndDateTime: nextClassToAttend?.event?.end_timestamp,
    };
    nextClassTime.join = this.shouldJoinClass(
      nextClassToAttend?.event?.start_timestamp
    );
    let upcomingClasses = this.state.upcomingClasses || [];
    let previousClasses = this.state.previousClasses || [];

    let upcomingClassesTabs = this.renderClassList(upcomingClasses, true) || [];
    let previousClassesTabs =
      this.renderClassList(previousClasses, false) || [];

    return (
      <div className="component-flive main-wrapper">
        {/* //FLV-113 */}
        {!this.props.reduxData?.user?.navbarFlag && <Navbar />}
        {/* //FLV-113 */}
        <div
          id="question-icon"
          onClick={() => {
            this.handleStickyClass("remove");
            this.setState({ showCustomerSupportModal: true });
          }}
        >
          <img src={questionIcon} />
        </div>

        <div
          className={[
            "wrapperSecion",
            this.props.reduxData?.appConfig?.showNavbar ? "" : "padding-top-0",
            //FLV-113
            this.props.reduxData?.user?.navbarFlag ? "padding-top-0" : "",
            //FLV-113
          ].join(" ")}
        >
          <ClassDetails
            selectedClassData={this.state.selectedClassData}
            visible={this.state.showDetailsModal}
            onClose={() => {
              this.setState(
                {
                  showDetailsModal: !this.state.showDetailsModal,
                },
                () => setTimeout(() => this.handleStickyClass("add"), 500)
              );
            }}
          />
          <div className="container overflow-hidden newcontainer">
            <div className="row align-items-start column-reverse">
              <div className="col-md-3 left-column updatedleftcolumn">
                <div>
                  <UserCard
                    sendAnalytics={(event) => {
                      this.sendAnalytics(event);
                    }}
                  />
                  <RemainingClassCard />
                </div>
                <TeacherStudentCard
                  openChildrenModal={() => {
                    this.sendAnalytics(EVENT.VIEW_BATCHMATES);
                    this.handleStickyClass("remove");
                    this.setState({ showChildrenListPopup: true });
                  }}
                />

                <ReportsCard
                  onShow={() => this.props.history.push("/user/reports")}
                />
              </div>
              <div className="col-md-6 center-column">
                <BatchSelector
                  onSelect={(batchId) => {
                    this.setState(
                      {
                        isLoading: true,
                        resPrevious: {},
                        resUpcoming: {},
                        previousClasses: [],
                        upcomingClasses: [],
                      },
                      () => this.getClasses("", batchId),
                      this.props.getHomework(batchId)
                    );
                  }}
                />
                <div className="osahan-account-page-right tab-container-list  ">
                  <Tabs
                    activeKey={this.state.typeOfClass}
                    onSelect={this.setClassType}
                    defaultActiveKey={this.state.typeOfClass}
                    className="tab-box "
                  >
                    <Tab
                      eventKey="upcoming"
                      tabClassName={"upcoming-tab"}
                      label={
                        <div style={{ display: "flex" }}>
                          <img className="tabIcon " src={CalendarNext} />
                          UPCOMING CLASSES
                        </div>
                      }
                    >
                      <div className="tab-1">
                        <Accordion>
                          {/* Section: Upcoming Classes*/}
                          <div
                            // id={'upcoming-classes'}
                            onScroll={this.onScrollClasses}
                            className={[
                              "classes-list",
                              this.state.expandedListView === "upcoming"
                                ? "-expanded"
                                : "",
                            ].join("")}
                          >
                            {upcomingClassesTabs.length ? (
                              ""
                            ) : (
                              <h6
                                style={{
                                  marginTop: "40px",
                                  textAlign: "center",
                                }}
                              >
                                No Upcoming Classes
                              </h6>
                            )}
                            {upcomingClassesTabs}
                            {this.state.isLoading && (
                              <div className={"load-more"}>
                                <Spin />
                                Loading...
                              </div>
                            )}
                          </div>
                        </Accordion>
                        {upcomingClassesTabs?.length > 3 && (
                          <button
                            onClick={() => {
                              // if (
                              //   this.state.expandedListView === "upcoming"
                              //   && this.props.reduxData.student?.classes?.upcoming?.has_next
                              // ) {
                              //   return this.handlePageChange();
                              // }
                              this.setState({
                                expandedListView: this.state.expandedListView
                                  ? null
                                  : "upcoming",
                              });
                            }}
                            className="gray-btn"
                          >
                            {this.state.expandedListView === "upcoming"
                              ? // this.props.reduxData.student?.classes?.upcoming
                                //     ?.has_next
                                //   ? "Show More"
                                //   : "See Less"
                                "See Less"
                              : "See All"}
                            {this.state.isLoading && <Spin />}
                          </button>
                        )}
                      </div>
                    </Tab>
                    <Tab
                      eventKey="previous"
                      tabClassName={"previous-tab"}
                      label={
                        <div style={{ display: "flex" }}>
                          <img className="tabIcon" src={CalendarPrev} />
                          PREVIOUS CLASSES
                        </div>
                      }
                    >
                      <div className="tab-2 tab-pane">
                        <Accordion>
                          {/* Section: Previous Classes*/}
                          <div
                            // id={'previous-classes'}
                            onScroll={this.onScrollClasses}
                            className={[
                              "classes-list",
                              this.state.expandedListView === "previous"
                                ? "-expanded"
                                : "",
                            ].join("")}
                          >
                            {previousClassesTabs.length ? (
                              ""
                            ) : (
                              <h6
                                style={{
                                  marginTop: "40px",
                                  textAlign: "center",
                                }}
                              >
                                No Previous Classes
                              </h6>
                            )}
                            {previousClassesTabs}
                            {this.state.isLoading && (
                              <div className={"load-more"}>
                                <Spin />
                                Loading...
                              </div>
                            )}
                          </div>
                        </Accordion>
                        {previousClassesTabs?.length > 3 && (
                          <button
                            onClick={() => {
                              // if (
                              //   this.state.expandedListView === "previous"
                              //   && this.props.reduxData.student?.classes?.previous?.has_next
                              // ) {
                              //   return this.handlePageChange();
                              // }
                              this.setState({
                                expandedListView: this.state.expandedListView
                                  ? null
                                  : "previous",
                              });
                            }}
                            className="gray-btn"
                          >
                            {this.state.expandedListView === "previous"
                              ? // this.props.reduxData.student?.classes?.previous
                                //     ?.has_next
                                //   ? "Show More"
                                //   :
                                "See Less"
                              : "See All"}
                            {this.state.isLoading && <Spin />}
                          </button>
                        )}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
                <AnnouncementsCard
                  isMobileView={true}
                  openCustomerSupportModal={() => {
                    this.handleStickyClass("remove");
                    this.setState({ showCustomerSupportModal: true });
                  }}
                  openAnnouncementsModal={() => {
                    this.sendAnalytics(EVENT.VIEW_UPDATES);
                    this.handleStickyClass("remove");
                    this.setState({ announcementsModalVisible: true });
                  }}
                  openTestsModal={() => {
                    this.handleStickyClass("remove");
                    this.setState({ testModalVisible: true });
                  }}
                />
              </div>
              <div
                className={[
                  "col-md-3 right-column",
                  this.props.reduxData?.appConfig?.showNavbar
                    ? ""
                    : "full-width",
                ].join(" ")}
              >
                <NextClass
                  nextClassTime={nextClassTime}
                  sendAnalytics={(ev, dt) => this.sendAnalytics(ev, dt)}
                  nextClassToAttend={nextClassToAttend}
                />
                <AnnouncementsCard
                  openCustomerSupportModal={() => {
                    this.handleStickyClass("remove");
                    this.setState({ showCustomerSupportModal: true });
                  }}
                  openAnnouncementsModal={() => {
                    this.sendAnalytics(EVENT.VIEW_UPDATES);
                    this.handleStickyClass("remove");
                    this.setState({ announcementsModalVisible: true });
                  }}
                  openTestsModal={() => {
                    this.handleStickyClass("remove");
                    this.setState({ testModalVisible: true });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomerSupportModal
          visible={this.state.showCustomerSupportModal}
          closeModal={() => {
            this.setState(
              {
                showCustomerSupportModal: !this.state.showCustomerSupportModal,
              },
              () => setTimeout(() => this.handleStickyClass("add"), 500)
            );
          }}
        />
        <ChildrenModal
          batchId={this.props.reduxData?.student?.batchDetails?.[0]?.batch_id}
          visible={this.state.showChildrenListPopup}
          closeModal={() => {
            this.setState(
              {
                showChildrenListPopup: !this.state.showChildrenListPopup,
              },
              () => setTimeout(() => this.handleStickyClass("add"), 500)
            );
          }}
        />
        <div id="onlyForAnnouncementModal">
          <AnnouncementModal
            reduxData={this.props.reduxData}
            visible={true}
            closeModal={() => {
              this.props.history.push("/user", {
                urlToRedirect: this.props.location.pathname,
              });
            }}
          />
        </div>

        <TestsModal
          visible={this.state.testModalVisible}
          closeModal={() => {
            this.setState(
              {
                testModalVisible: !this.state.testModalVisible,
              },
              () => setTimeout(() => this.handleStickyClass("add"), 500)
            );
          }}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({ reduxData: { ...state } }),
  (dispatch) =>
    bindActionCreators(
      {
        ...userActions,
        ...studentActions,
        ...attendanceActions,
        ...testActions,
        ...subscriptionsActions,
        ...announcementActions,
        ...homeworkActions,
      },
      dispatch
    )
)(HomeworksPopUp);
