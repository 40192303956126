import React from "react";
import Navbar from "../../components/Navbar";
import ReadAlongLayout from "./ReadAlongLayout";

const ReadAlong = () => {
  return (
    <div>
      <Navbar />
      <ReadAlongLayout />
    </div>
  );
};

export default ReadAlong;
