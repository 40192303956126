import React, { Component } from "react";
import { Modal } from "antd";
import { CrossIcon } from "./../../../../assets/image/svg";
import default_child from "./../../../../assets/image/child_img.png";

const StudentsModal = ({ children = [], visible, closeModal, type }) => {
  return (
    <Modal
      wrapClassName="children-modal"
      maskStyle={{
        background: "rgba(0,0,0,0.45)",
      }}
      title={`Assignment ${type}`}
      width={950}
      visible={visible}
      onOk={null}
      onCancel={closeModal}
      okText="Submit"
      closeIcon={
        <i
          aria-label="icon: close"
          className="anticon anticon-close ant-modal-close-icon"
        >
          <CrossIcon />
        </i>
      }
    >
      <div className="children-cards d-flex wrap">
        {children && children.length > 0 ? (
          children.map((child) => (
            <div className="children-card" key={child.id}>
              <div className={"batchmate-content"}>
                <div className={"profile"}>
                  <img
                    className="listing-row-profile-pic"
                    style={{ backgroundColor: "white" }}
                    src={child.image ? child.image : default_child}
                    alt="Profile"
                  />
                </div>
                <div className="batchmate-detail">
                  <div className="name">
                    <h2>{child.name}</h2>
                  </div>
                  {/* <div className="stats">
                    <div className="freadom-points">
                      <p className="purple-text">{child?.freadom_point}</p>
                      <p>Freadom Points</p>
                    </div>
                    <div className="streak-counts">
                      <p className="purple-text">{child?.streak}</p>
                      <p>Streak Count</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className={"no-data-label"}>
            {type === "Completed"
              ? "There are no students who have completed the assignment."
              : type === "Not Started"
              ? "There are no students who have started the assignment."
              : type === "In Progress"
              ? "There are no students currently in progress."
              : "There are no students who have completed the assignment."}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default StudentsModal;
