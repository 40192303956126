import React from "react";
import Navbar from "../../components/Navbar";
import ReadAloudLayout from "./ReadAloudLayout";

const ReadAloud = () => {
  return (
    <div>
      <Navbar />
      <ReadAloudLayout />
    </div>
  );
};

export default ReadAloud;
