import React from 'react'
import successImg from "../../assets/image/check_img.png";
import './index.scss'

const ToastComponent = ({ message }) => {
    return (
        <div className="toast_container">
            <div className='toast_body'>
            <img
                src={successImg}
                style={{ width: "100px", height: "100px"}}
            />
            <br />
            <p> {message} </p>

            </div>
        </div>
    )
}

export default ToastComponent