import styled from "styled-components";
// const primary = '#341744';
// const bg = '#432666';
export const secondary = "#ff8a23";
export const border = "#eee";
export const textColor = "#84817a";
export const borderDark = "#d1ccc0";
export const success = "#26de81";
export const error = "#eb4d4b";


// export const Top = styled.h1`
//  width: "100%",
//     height: "234px",
//     left:"800px",
//     bottom: "2966px",
//     background: "linear-gradient( 1.77deg,  #9134D8 0%, #7A6FFF 96.97%)
// `

export const PlusButton = styled.button`
  width: 100px;
  height: 100px;
  position: fixed;
  bottom: 65px;
  right: 9%;
  z-index: 1000;
  display: ${props => (props.hideBtn ? "none" : "flex")};
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: white;
  outline: none;
  cursor: pointer;
  border-radius: 100px;
  background-color: #6f42c1;
  border: solid 2px #ffffff;
  transition: all 0.3s;
  transform: ${props => (props.hide ? "scale(0)" : "scale(1)")};

  &:hover {
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 138, 35, 0.8);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(3, 168, 124, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(3, 168, 124, 0);
    }
  }
`;

export const PlusIcon = styled.img`
  width: 120px;
  height: 80px;
  text-align: center;
  transition: 0.3s;
  // transform: ${props => (props.cross ? "rotate(45deg)" : "rotate(0deg)")};
`;

export const MainBodyContainer = styled.div`
  // margin-top: -20px;
  position: relative;
`;

export const AlbumContainer = styled.div`
  padding: 8px 0px;
  span {
    color: #000;
    font-weight: bold;
    font-size: var(--sm-font-size);
  }
`;
