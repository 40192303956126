/**
 * HTML5 Audio Read-Along
 * @author Weston Ruter, X-Team
 * @license MIT/GPL
 * https://github.com/westonruter/html5-audio-read-along
 */

var ReadAlongInIt = {
    text_element: null,
    audio_element: null,
    autofocus_current_word: true,
    words: [],
    audio: null,
    word_count : null,
    startTime : null,
    playbackRate : 1,
    // pauselength:0,
    init: function (args) {
        var name;
        for (name in args) {
            this[name] = args[name];
        }
        this.word_count = 0;
        this.startTime = 0;
        this.generateWordList();
        this.addEventListeners();
        this.selectCurrentWord();
    },
    playButtonPress : function (args) {
       
        var name;
        for (name in args) {
            this[name] = args[name];
        }
        this.word_count = 0;
        this.startTime = new Date();
        this.selectCurrentWord();
        // console.log("adding speaking class");
        // this.text_element.classList.add('speaking');

        this.removeWordSelection();
    },

    // playButtonPressed2ndTime : function(args, pauseduration){
    // // const current_time=new Date();
    // // const play2ndtime=current_time.getTime();
    // // console.log("2nd time play",play2ndtime);
    // var name;
    // for (name in args) {
    //     this[name] = args[name];
    // }
    // this.pauselength=pauseduration;

    // console.log("pauselength", pauseduration);
    // const current_word=this.getCurrentWord();
    // console.log("my current word is",current_word);
    // const word_count_func = current_word?.index;
    // console.log("My wordcount is",word_count_func);
    // this.word_count=word_count_func;
    // this.selectCurrentWord();
    // },

    
    /**
     * Build an index of all of the words that can be read along with their begin,
     * and end times, and the DOM element representing the word.
     */
    clearAllClassNames: function (element) {
        // console.log("clearing all class names"); 
        var word_els = element.querySelectorAll('[data-begin]');
        for (let i = 0; i < word_els.length; i++) {
            word_els[i].classList.remove('speaking');
            word_els[i].classList.remove('selected');
        } 
    },
    generateWordList: function () {
        // console.log("generating word list");
        var word_els = this.text_element.querySelectorAll('[data-begin]');
        var iframe = document.querySelector("iframe");
        var elements = iframe.contentWindow.document.querySelector('.passage').getElementsByTagName('p')
        var word_lists = []
        // console.log(elements)
        // console.log(elements[0])
        for (let i = 0; i < elements.length; i++) {
            var ww=Array.from(elements[i].getElementsByTagName('span'))
            for (let j = 0; j < ww.length; j++) {
            word_lists.push(ww[j])
            }
          }
        // console.log(word_lists);
        this.words = Array.prototype.map.call(word_lists, function (word_el, index) {
            var word = {
                'begin': parseFloat(word_el.dataset.begin),
                'dur': parseFloat(word_el.dataset.dur),
                'element': word_el
            };

            word_el.tabIndex = 0; // to make it focusable/interactive
            word.index = index;
            word.end = word.begin + word.dur;
            word_el.dataset.index = word.index;
            return word;
        });
        // console.log(this.words);
    },

    /**
     * From the audio's currentTime, find the word that is currently being played
     * @todo this would better be implemented as a binary search
     */
    getCurrentWord: function () {
        // console.log("getting current word");
        var i;
        var len;
        var is_current_word;
        var word = null;
        // console.log(this.words);
        // console.log(this.audio_element);
        // console.log(this.audio_element.currentTime);
        // for (i = 0, len = this.words.length; i < len; i += 1) {
        //     is_current_word = (
        //         (
        //             this.audio_element.currentTime >= this.words[i].begin
        //             &&
        //             this.audio_element.currentTime < this.words[i].end
        //         )
        //         ||
        //         (this.audio_element.currentTime < this.words[i].begin)
        //     );
        //     if (is_current_word) {
        //         word = this.words[i];
        //         break;
        //     }
        // }

        return this.words[this.word_count];

        if (!word) {
            // throw Error('Unable to find current word and we should always be able to.');
        }
        return word;
    },

    _current_end_select_timeout_id: null,
    _current_next_select_timeout_id: null,

    /**
     * Select the current word and set timeout to select the next one if playing
     */
    selectCurrentWord: function() {
     
        var that = this;
        var current_word = this.getCurrentWord();

        

        console.log("current word: ", current_word);
        var is_playing = !this.audio_element.paused;
        // console.log("is playing: ", is_playing);
        // console.log("Speaking: ",current_word.element.classList.contains('speaking'));
        if (current_word!==null && !current_word.element.classList.contains('speaking')) {
            this.removeWordSelection();
            // console.log("adding speaking class");
            current_word.element.classList.add('speaking');
            current_word.element.classList.add('bg-warning');
            if (this.autofocus_current_word) {
                // // console.log(current_word.element)
                current_word.element.focus();
            }
        }

        /**
         * The timeupdate Media event does not fire repeatedly enough to be
         * able to rely on for updating the selected word (it hovers around
         * 250ms resolution), so we add a setTimeout with the exact duration
         * of the word.
         */
        var is_audio_played = true;
        // console.log(this.audio);
        if(this.audio){
            is_audio_played = !this.audio.paused;
        }else{
            is_audio_played = false
        }
        
        // console.log("is_audio_played: ", is_audio_played);
        if (is_playing || is_audio_played) {
            // Remove word selection when the word ceases to be spoken
            console.log("current time:", this.audio_element);
            var currentTime = new Date();
            console.log("Start time",this.startTime);
            const diffInSeconds =  ( (currentTime.getTime() - this.startTime.getTime()) / 1000 ) * this.playbackRate;
            console.log("time difference:", diffInSeconds);
            var seconds_until_this_word_ends = current_word.end - diffInSeconds; // Note: 'word' not 'world'! ;-)
            console.log("playback rate:", this.playbackRate);
            console.log("seconds_until_this_word_ends - before: ", seconds_until_this_word_ends);

            if (typeof this.playbackRate === 'number' && !isNaN(this.playbackRate)) {
                seconds_until_this_word_ends *= 1.0/(this.playbackRate);
            }

            console.log("seconds_until_this_word_ends - after: ", seconds_until_this_word_ends);
            clearTimeout(this._current_end_select_timeout_id);
            this._current_end_select_timeout_id = setTimeout(
                function () {
                    // console.log('indide timeout');
                    // check audio paused from this.audio
                    var is_audio_paused = that.audio.paused;
                    // console.log("is_audio_paused: ", is_audio_paused);
                    if (!that.audio_element.paused || !is_audio_paused) { // we always want to have a word selected while paused
                        // console.log("inside if");
                        // console.log("removing speaking class");
                        // console.log("before contains classname: ", current_word.element.classList.contains('speaking'));
                        current_word.element.classList.remove('speaking');
                        
                    }
                },
                Math.max(seconds_until_this_word_ends * 1000, 0)
            );

            // Automatically trigger selectCurrentWord when the next word begins
            var next_word = this.words[current_word.index + 1];
            // console.log("next_word:",next_word);
            if (next_word) {
                var seconds_until_next_word_begins = next_word.begin - diffInSeconds;
                var orig_seconds_until_next_word_begins = seconds_until_next_word_begins; // temp
                console.log("seconds_until_next_word_begins - before:",seconds_until_next_word_begins);
                if (typeof this.playbackRate === 'number' && !isNaN(this.playbackRate * 0.8)) {
                    seconds_until_next_word_begins *= 1.0/(this.playbackRate );
                }
                console.log("seconds_until_next_word_begins - after:",seconds_until_next_word_begins);
                var speed = 1000;
                
                clearTimeout(this._current_next_select_timeout_id);
                this._current_next_select_timeout_id = setTimeout(
                    function () {
                        // console.log('inside function');
                        // console.log("word count before:",that.word_count );
                        that.word_count = that.word_count  +1;
                        // // console.log("word_count: ", that.word_count);
                        that.selectCurrentWord();
                    },
                    Math.max(seconds_until_next_word_begins * 1000, 0)
                );
            }
        }


    },

    removeWordSelection: function() {
        // console.log("removing word selection");
        // There should only be one element with .speaking, but selecting all for good measure
        var iframe = document.querySelector("iframe");
        var elements = iframe.contentWindow.document.querySelector('.passage')!==null?
                       iframe.contentWindow.document.querySelector('.passage').getElementsByTagName('p')
                       :[];
        var word_lists = []
        for (let i = 0; i < elements.length; i++) {
            var ww=Array.from(elements[i]?.getElementsByTagName('span'))
            for (let j = 0; j < ww.length; j++) {
            word_lists.push(ww[j])
            }
          }
        // console.log("word_lists: ", word_lists);
          
        var spoken_word_els = this.text_element?.querySelectorAll('span[data-begin].speaking');
       
        Array.prototype.forEach.call(word_lists, function (spoken_word_el) {
            // console.log("spoken_word_el: ", spoken_word_el);
            // console.log("removing speaking class");
            spoken_word_el.classList.remove('speaking');
        });
    },


    addEventListeners: function () {
        // console.log("adding event listeners");
        var that = this;

        /**
         * Select next word (at that.audio_element.currentTime) when playing begins
         */
        that.audio_element.addEventListener('play', function (e) {
            // console.log("playing");
            that.selectCurrentWord();
            // console.log("adding speaking class");
            that.text_element.classList.add('speaking');
        }, false);

        /**
         * Abandon seeking the next word because we're paused
         */
        that.audio_element.addEventListener('pause', function (e) {
            // console.log("pausing");
            that.selectCurrentWord(); // We always want a word to be selected
            // console.log("removing speaking class");
            that.text_element.classList.remove('speaking');
        }, false);

        /**
         * Seek by selecting a word (event delegation)
         */
        function on_select_word_el(e) {
            // console.log("on select word el");
            if (!e.target.dataset.begin) {
                return;
            }
            e.preventDefault();

            var i = e.target.dataset.index;
            that.audio_element.currentTime = that.words[i].begin + 0.01; //Note: times apparently cannot be exactly set and sometimes select too early
            that.selectCurrentWord();
        }
        // that.text_element.addEventListener('click', on_select_word_el, false);
        that.text_element.addEventListener('keypress', function (e) {
            // console.log("keypress");
            if ( (e.charCode || e.keyCode) === 13 /*Enter*/) {
                on_select_word_el.call(this, e);
            }
        }, false);

        /**
         * Spacebar toggles playback
         */
        document.addEventListener('keypress', function (e) {
            if ( (e.charCode || e.keyCode) === 32 /*Space*/) {
                e.preventDefault();
                if (that.audio_element.paused) {
                    that.audio_element.play();
                }
                else {
                    that.audio_element.pause();
                }
            }
        }, false);

        /**
         * First click handler sets currentTime to the word, and second click
         * here then causes it to play.
         * @todo Should it stop playing once the duration is over?
         */
        that.text_element.addEventListener('dblclick', function (e) {
            e.preventDefault();
            that.audio_element.play();
        }, false);

        /**
         * Select a word when seeking
         */
        that.audio_element.addEventListener('seeked', function (e) {
            // console.log("seeked");
            that.selectCurrentWord();

            /**
             * Address probem with Chrome where sometimes it seems to get stuck upon seeked:
             * http://code.google.com/p/chromium/issues/detail?id=99749
             */
            var audio_element = this;
            if (!audio_element.paused) {
                var previousTime = audio_element.currentTime;
                setTimeout(function () {
                    if (!audio_element.paused && previousTime === audio_element.currentTime) {
                        audio_element.currentTime += 0.01; // Attempt to unstick
                    }
                }, 500);
            }
        }, false);

        /**
         * Select a word when seeking
         */
        that.audio_element.addEventListener('ratechange', function (e) {
            that.selectCurrentWord();
        }, false);
    }

    
};

export default ReadAlongInIt;
