const setSearchResult = (data) => ({
    type: "SET_SEARCH_RESULT",
    data,
  });
  const setLevels = (data) => ({
    type: "SET_LEVELS",
    data,
  });

  const setClassMates = (data) => ({
    type: "SET_CLASS_MATES",
    data,
  });

  const setTeacherSchoolClass = (data) => ({
    type: "SET_TEACHER_SCHOOL_CLASS",
    data,
  });

  const getCurrentTeacherSchoolClass = () => ({
    type: "GET_CURRENT_TEACHER_SCHOOL_CLASS",
  });

  const setAllAssignment = (data) => ({
    type: "SET_ALL_ASSIGNMENT",
    data,
  });

  const setGuidedPathUnits= (data) => ({
    type: "SET_GUIDED_PATH_UNITS",
    data,
  });

  export const mutation = {
    setSearchResult,
    setLevels,
    setClassMates,
    setTeacherSchoolClass,
    getCurrentTeacherSchoolClass,
    setAllAssignment,
    setGuidedPathUnits
  };

