import React from "react";
import "./QuizBanner.scss";
import { Button } from "antd";

const QuizBanner = (props) => {
  const { data } = props;
  const bannerBackground = data
    ? `url(${data})`
    : "linear-gradient(to right, rgb(62, 34, 114), rgba(32, 115, 182, 0.83),rgba(92, 189, 221, 0.33))";
  return (
    <div
      style={{
        ...props.style,
        backgroundImage: bannerBackground,
      }}
      className="component-quiz-banner banner-image"
    >
      <div
        style={{
          padding: "58px 40px",
        }}
        className="text-container"
      >
        <div className="name">READY FOR THE CHALLENGE?</div>
        <div>&nbsp;</div>
        <div className="description">
          Test your wits with these curated quizzes and collect Freados while
          sharpening your brain!
        </div>
        {/* <Button type="primary"
              className="primary-btn banner-btn"
              size="large">
          Take the challenge
        </Button> */}
      </div>
    </div>
  );
};

export default QuizBanner;
