import headTecherIcon from "../../../../../assets/image/graduation-cap.png";
import DefaultUserIcon from "../../../../../assets/image/default-user.png";
import TeamsIcon from "../../../../../assets/image/team.svg";
import React from "react";
import { connect } from "react-redux";
import './index.scss'
import * as EVENT from "../../../../../utils/eventKeys";


const TeacherStudentCard = ({ reduxData, openChildrenModal, ...props }) => {
    const student = reduxData?.student;
    const batchDetails = student?.batchDetails;
    const teacherInfo = (batchDetails?.length > 0) ? batchDetails[0]?.teacher : null;

    return <div
        className="osahan-account-page-left shadow-sm bg-white rounded-6 mt-4 rounded border-bottom head-teacher">
        <div className="p-4 teacher-section">
            <div className="osahan-user text-center">
                <div className="osahan-user-media">
                    <div className="osahan-user-media-body">
                        <div className="cardTitle-row">
                            <div className="cardTitle">
                                <img
                                    className="headTecherIcon"
                                    src={headTecherIcon}
                                />
                                Teacher
                            </div>
                        </div>
                        <section className="post-heading">
                            <div className="row">
                                <div className="col-md-11 text-left">
                                    <div className="media">
                                        <div className="media-left">
                                            <div>
                                                <img className="media-object photo-profile"
                                                    src={teacherInfo ? teacherInfo.pic : DefaultUserIcon}
                                                    alt="..." />
                                            </div>
                                        </div>
                                        <div className="media-body mediaBody">
                                            <h4 className="media-heading mb-0">{teacherInfo ? teacherInfo.user?.name : "No Info"}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="post-body">
                            <span style={{ display: "block" }}
                                className="post-text">{teacherInfo ? teacherInfo.bio : "No Info"}</span>
                        </section>
                        <div className="cardTitle-row mt-4">
                            <div className="cardTitle cardTitleSB">
                                <div>
                                    <img className="headTecherIcon" src={TeamsIcon} />
                                    my batchmates
                                </div>
                                <a onClick={() => {
                                    //FREAD-1342
                                    props.sendCtEvents(EVENT.FLIVE_VIEW_ALL_BATCH_BUTTON);
                                    //FREAD-1342
                                    openChildrenModal();
                                }}
                                //FREAD-1342
                                id="FLV_BATCH_VIEW_ALL"
                                //FREAD-1342

                                >
                                    View All
                                </a>
                            </div>
                        </div>
                        <div className="friendMember">
                            {
                                <BatchMates student={reduxData?.student}
                                    data={batchDetails?.length > 0 ? batchDetails[0].children : []} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const BatchMates = ({ data, student }) => {
    if (data && data.length > 0) {
        return data.filter(child => child?.id !== student?.id)
            .map((child, index) => (
                <img
                    className="friendPic"
                    src={child?.image ? child?.image : DefaultUserIcon}
                    alt=""
                />
            ));
    } else return null
}

export default connect(
    (state) => ({ reduxData: { ...state } }),
    null
)(TeacherStudentCard);
