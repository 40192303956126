export const initialState = {
  newsList: [],
  activeNewsDetail: {},
  // Pagination related state
  isLoading: false,
  has_next: true,
  next_page: 1,
  offset: 0,
  prev_page: 0,
};
