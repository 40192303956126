import React, { useEffect } from 'react';
import './index.scss';
import ProfileCompleteImage from 'assets/image/profile-complete-img.svg';
import { Button } from 'antd';
import { withRouter } from 'react-router';
import Navbar from "components/Navbar";
import * as studentActions from '../../../../../store/student/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { storage as LocalStorage } from "services/config/storage";

const FormComplete = (props) => {
    useEffect(() => {
        if (!LocalStorage.fetch.authToken()) {
            return props.history.push("/onboarding") //props.history.push("/login")
        }
        if (props.reduxData.student?.batchDetails?.length > 0) {
            //FREAD-937
            // window.addEventListener("popstate", (e) => {
            //     return props.history.push("/");
            //   });
            return props.history.replace("/user")
        }
    }, []);
    return (
        <div
            className={['student-profile-form', props.reduxData?.appConfig?.showNavbar ? '' : 'zero-padding'
            , !props.reduxData?.user?.navbarFlag ? '' : 'zero-padding'].join(' ')}>
            <div className={'student-profile-form-content'}>
                {/* //FREAD-1276 */}
                    {!props.reduxData?.user?.navbarFlag && <Navbar />}
                {/* //FREAD-1276 */}
                <div
                    className={['form-complete', props.reduxData?.appConfig?.showNavbar ? '' : 'full-height'].join(' ')}>
                    <div className="form-complete-text">
                        <h1>
                            <span>Batch will be allocated soon!</span>
                        </h1>
                        <p>Thank you for completing your profile. We have noted your batch preference.Your batch will
                            start as soon as we are ready. Check back later to know more about your batch.</p>
                        <div className="form-complete-buttons">
                            <Button
                                className={"home-btn"}
                                onClick={() => (props.history.replace("/story"))}>Read Stories</Button>
                            <Button className="edit-btn" onClick={() => props.history.push("/student/profile/edit")}>
                                EDIT PROFILE
                            </Button>
                        </div>
                    </div>
                    <div className="form-complete-image">
                        <img src={ProfileCompleteImage} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default connect(
    state => ({ reduxData: state }),
    dispatch => bindActionCreators({ ...studentActions }, dispatch)
)(withRouter(FormComplete));
