import { Api } from "./config/request";

const getAnnouncementsForBatch = (batchID) =>
  Api.getFliveRequest(`batches/${batchID}/announcements/`, true);

const addAnnoucementForBatch = (batchID, payload) =>
  Api.postFliveRequest(
    `batches/${batchID}/announcements/`,
    JSON.stringify(payload),
    true
  );

const getHomeworkForBatch = (batchID) =>
  Api.getFliveRequest(`batches/homework/${batchID}/`, true);

const getWorksheetForBatch = (batchID) =>
  Api.getFliveRequest(`batches/worksheet/${batchID}/`, true);

export const AnnouncementApiCalls = {
  getAnnouncementsForBatch,
  addAnnoucementForBatch,
  getHomeworkForBatch,
  getWorksheetForBatch,
};
