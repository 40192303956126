import axios from "axios";
import { Api } from "./config/request";

export const getPassage = (params) =>
  Api.getRequest(`cmfluency/v1/fluencypassages/${params}`);

export const getReportData = (params) =>
  Api.getRequest(
    `cmfluency/v1/fluencyreports?freadom_id=${params}`
  );

export const storeCMFluencyData = async (payload) => 
    Api.postRequest("cmfluency/v1/iitb_fluency/", payload);


export const uploadAudio = (payload) =>
  Api.postRequest("misc/v1/media/", payload);

export const cmfCommunication = (payload) =>
  Api.postRequest("misc/v1/cmf-module/communication/", payload);

export const verifySchoolCode = (code) =>
  Api.getRequest(`school/v1/validate-school-code/?school_code=${code}`);

export const CMFluencyApiCalls = {
  getPassage,
  storeCMFluencyData,
  uploadAudio,
  cmfCommunication,
  verifySchoolCode,
  getReportData,
};
