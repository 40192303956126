import {initialState} from "./state";

export const teacher = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SEARCH_RESULT':
            return {
                ...state,
                searchResult: action.data
            }
        case 'SET_LEVELS':
            return {
                ...state,
                levels: action.data
            }
        case 'SET_CLASS_MATES':
            return {
                ...state,
                classMates: action.data
            }
        case 'SET_TEACHER_SCHOOL_CLASS':
            return {
                ...state,
                TeacherSchoolClass: action.data
            }
        case 'SET_ALL_ASSIGNMENT':
            return {
                ...state,
                allAssignment: action.data
            }
        case 'SET_GUIDED_PATH_UNITS':
            return {
                ...state,
                guidedPathUnits: action.data
            }
        case 'GET_CURRENT_TEACHER_SCHOOL_CLASS':
            return state

        default:
            return state;
    }
};
