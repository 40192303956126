import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from 'react-modal';
import "./index.scss";
import clockImg from "../../../../../assets/image/clock.png";
import { Switch } from 'antd';
import Infoicon from '../../../../../assets/image/info.png';
import { RiErrorWarningFill} from "react-icons/ri";

const AnnouncementsCard = ({
  reduxData,
  isMobileView,
  openAnnouncementsModal,
  openTestsModal,
  openCustomerSupportModal,
  fetchAllAssignments,
  deactivateAssignment,
  allAssignments
}) => {

  const [fetData, setFetchData] = useState(false);
  const [assignmentData, setAssignmentData] = useState(allAssignments);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);

  useEffect(() => {
    if (!fetData) {
      fetchAllAssignments();
      setFetchData(true);
    }
    setAssignmentData(allAssignments);
  }, [fetData, fetchAllAssignments, allAssignments]);

  const deactivateAss = (data) => {
    deactivateAssignment(data);
  }

  const openAssignmentModal = (assignment) => {
    setSelectedAssignment(assignment);
    setModalVisible(true);
  }

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <div className={`announcements-card osahan-account-page-left-new shadow-sm bg-white rounded-6 pt-1 mt-0 update-card-${isMobileView ? "mobile-view" : "desktop"} rounded announcements`} style={{marginTop:'5px !important', heiht:'57vh !important'}}>
      <div className="announcement-header">
        <RiErrorWarningFill style={{width:'1.5rem',height:'1.5rem',minWidth:'1.5rem'}}/>
        <h2 className="announcement-title">Coming Soon</h2>

      </div>
      {/* <div className="osahan-user">
        <div className={`osahan-user-media ${isMobileView ? "announcement-mobile-view" : ""}`}>
          {assignmentData && assignmentData.map((data, index) => (
            <div className="assignment d-flex justify-content-between">
              <div className=""  key={index} onClick={() => openAssignmentModal(data)}>
                <span>{data.name}</span>

                <div>
                  <img src={clockImg} style={{ width: "14px" }} alt="clock"></img>
                  <span style={{ fontSize: "12px" }}> {data.deadline}</span>
                </div>
              </div>
              <div>
                <Switch
                  size="small"
                  checked={data.is_deleted ? false : true}
                  onChange={() => deactivateAss(data)}
                />
              </div>

            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={modalVisible}
        onRequestClose={handleCancel}
        contentLabel="Assignment Details"
        className="modal-content"
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        style={{
          overlay: {
            display: 'flex',
            alignItems: 'center',
            background: '#dbdbdb !important'
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '90%',
            maxHeight: '80vh',
            padding: '20px',
            borderRadius: '10px',
            backgroundColor: 'white',
            overflowY: 'auto',
          }
        }}
      >
        <button className="modal-close" onClick={handleCancel}>
          &#x2715;
        </button>
        <h2>Assignment Details</h2>
        {selectedAssignment && (
          <div className="assignment-details">
            <p><strong>ID:</strong> {selectedAssignment.id}</p>
            <p><strong>Name:</strong> {selectedAssignment.name}</p>
            <p><strong>Description:</strong> {selectedAssignment.description}</p>
            <p><strong>Deadline:</strong> {selectedAssignment.deadline}</p>
            <p><strong>Status:</strong> {selectedAssignment.status}</p>
            <p><strong>Teacher ID:</strong> {selectedAssignment.teacher}</p>
            <p><strong>Created At:</strong> {selectedAssignment.created_at}</p>
            <p><strong>Modified At:</strong> {selectedAssignment.modified_at}</p>
            <p><strong>Deleted At:</strong> {selectedAssignment.deleted_at}</p>
            <p><strong>Is Deleted:</strong> {selectedAssignment.is_deleted ? "Yes" : "No"}</p>
            {selectedAssignment.tasks && (
              <div>
                <h3>Tasks:</h3>
                <ul>
                  {selectedAssignment.tasks.map((task, index) => (
                    <li key={index}>{task.id}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </Modal> */}
    </div>
  );
}

export default connect(
  (state) => ({ reduxData: { ...state } }),
  null
)(AnnouncementsCard);
